import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { iAddressBase, iAddressForm } from "../interfaces/address";
import { iSelectOption } from "../interfaces/selectOption";
import AddressBaseForm from "./addressBaseForm";

// TODO: Pendiente Agregar el funcionamiento de la sección "Horarios de clase".

/** Interfaz con las propiedades de para el formulario de una dirección */
interface iAddressFormProps {
    /** Id de la dirección. Se manda en el caso de la edición */
    id?: string;

    /** Modelo que maneja los errores. Sus propiedades se manejan en PascalCase ya que dependen de los modelos en el back */
    errors: { [key: string]: string[] };

    /** Función para manejar el submit */
    onSubmit: (addressData: iAddressForm, addressBaseData: iAddressBase) => void;

    /** Indica si se hizo submit */
    formSubmitted: boolean;
}

/** Datos iniciales para la dirección */
const defaultAddress: iAddressForm = {
    name: '',
    identifier: ''
}

/** Datos iniciales para la dirección base */
const defaultAddressBase: iAddressBase = {
    latitude: null,
    longitude: null,
    street: '',
    streetNumber: '',
    neighborhood: '',
    zipCode: '',
    city: '',
    state: ''
}

/**
 * Componente con el formulario de una dirección
 * 
 * @returns
 */
export default function AddressForm({ id, errors, onSubmit, formSubmitted }: iAddressFormProps) {
    const [addressModel, setAddressModel] = useState<iAddressForm>(defaultAddress);
    const [addressBaseModel, setAddressBaseModel] = useState<iAddressBase>(defaultAddressBase);
    const [levels, setLevels] = useState<iSelectOption[]>([]);

    useEffect(() => {
        if (id) {
            fetch(`/api/Admin/GetAddressFormData/${id}`)
                .then(res => {
                    if (!res.ok) {
                        window.location.href = `${window.location.origin}/Error`;
                    }

                    return res.json()
                })
                .then(data => {
                    const dataAddress = data as iAddressForm
                    const dataAddressBase = data as iAddressBase

                    // NOTE: las asignaciones siguientes son para asegurarse de que cada variable obtenga solo las propiedades requeridas.

                    const address: iAddressForm = {
                        name: dataAddress.name,
                        identifier: dataAddress.identifier
                    }

                    const addressBase: iAddressBase = {
                        latitude: dataAddressBase.latitude,
                        longitude: dataAddressBase.longitude,
                        street: dataAddressBase.street,
                        streetNumber: dataAddressBase.streetNumber,
                        neighborhood: dataAddressBase.neighborhood,
                        zipCode: dataAddressBase.zipCode,
                        city: dataAddressBase.city,
                        state: dataAddressBase.state
                    }

                    setAddressModel(address);
                    setAddressBaseModel(addressBase);
                });
        }
    }, [id]);

    useEffect(() => {
        fetch('/api/Level/GetLevels')
            .then(res => res.json())
            .then(data => {
                setLevels(data as iSelectOption[]);
            });
    }, []);

    const getButtonText = () => {
        if (id) {
            return formSubmitted ? 'Actualizando...' : 'Actualizar';
        } else {
            return formSubmitted ? 'Agregando...' : 'Agregar';
        }
    };

    /** Función que maneja el cambio de los inputs y textarea en el modelo */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        setAddressModel({
            ...addressModel,
            [name]: value
        });
    };

    /** Función que maneja los datos base de la dirección */
    const handleAddressChange = useCallback((updatedAddress: iAddressBase) => {
        setAddressBaseModel(updatedAddress);
    }, []);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        onSubmit(addressModel, addressBaseModel);
    }

    return (
        <form onSubmit={handleSubmit} className="uk-form-stacked">
            <div className="uk-child-width-1-1 uk-child-width-1-2@s" data-uk-grid>
                <fieldset className="uk-fieldset" disabled={formSubmitted}>
                    <div className="uk-form-horizontal">

                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="name">Nombre:</label>
                            <div className="uk-form-controls">
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={addressModel.name}
                                    className="uk-input"
                                    onChange={handleChange}
                                    required />

                                {errors?.Name && errors.Name.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>

                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="identifier">Identificador:</label>
                            <div className="uk-form-controls">
                                <input
                                    type="text"
                                    id="identifier"
                                    name="identifier"
                                    value={addressModel.identifier}
                                    className="uk-input"
                                    onChange={handleChange}
                                    required />

                                {errors?.Identifier && errors.Identifier.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>

                        <AddressBaseForm
                            model={addressBaseModel}
                            errors={errors}
                            onAddressChange={handleAddressChange}
                            requiredInputs={true}
                        />

                    </div>
                </fieldset>
                <fieldset className="uk-fieldset" disabled={formSubmitted}>

                    <legend className="uk-legend">
                        Horarios de la clase
                    </legend>

                    <div className="uk-form-horizontal">
                        <div className="uk-margin-small">
                            <label className="uk-form-label">
                                <input
                                    type="checkbox"
                                    name="ASD1"
                                    value="1"
                                    className="uk-checkbox uk-margin-small-right"
                                    onChange={handleChange} />

                                Lunes
                            </label>
                            <div className="uk-form-controls">
                                <div className="uk-child-width-expand uk-grid-small" data-uk-grid>
                                    <div>
                                        <select className="uk-select">
                                            <option>HourFrom</option>
                                        </select>
                                    </div>

                                    <div>
                                        <select className="uk-select">
                                            <option>HourTo</option>
                                        </select>
                                    </div>

                                    <div>
                                        <select className="uk-select">
                                            <option value="Niveles"></option>

                                            {levels.map(l => (
                                                <option key={l.key} value={l.key}>
                                                    {l.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="uk-width-auto">
                                        <button
                                            type="button"
                                            className="uk-button uk-button-link uk-text-primary">
                                            <Icon path={mdiPlus} size={0.7} />
                                        </button>
                                    </div>
                                </div>

                                {errors?.ASD1 && errors.ASD1.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label">
                                <input
                                    type="checkbox"
                                    name="ASD2"
                                    value="2"
                                    className="uk-checkbox uk-margin-small-right"
                                    onChange={handleChange} />

                                Martes
                            </label>
                            <div className="uk-form-controls">
                                <div className="uk-child-width-expand uk-grid-small" data-uk-grid>
                                    <div>
                                        <select className="uk-select">
                                            <option>HourFrom</option>
                                        </select>
                                    </div>

                                    <div>
                                        <select className="uk-select">
                                            <option>HourTo</option>
                                        </select>
                                    </div>

                                    <div>
                                        <select className="uk-select">
                                            <option value="Niveles"></option>

                                            {levels.map(l => (
                                                <option key={l.key} value={l.key}>
                                                    {l.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="uk-width-auto">
                                        <button
                                            type="button"
                                            className="uk-button uk-button-link uk-text-primary">
                                            <Icon path={mdiPlus} size={0.7} />
                                        </button>
                                    </div>
                                </div>

                                {errors?.ASD2 && errors.ASD2.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label">
                                <input
                                    type="checkbox"
                                    name="ASD3"
                                    value="3"
                                    className="uk-checkbox uk-margin-small-right"
                                    onChange={handleChange} />

                                Miércoles
                            </label>
                            <div className="uk-form-controls">
                                <div className="uk-child-width-expand uk-grid-small" data-uk-grid>
                                    <div>
                                        <select className="uk-select">
                                            <option>HourFrom</option>
                                        </select>
                                    </div>

                                    <div>
                                        <select className="uk-select">
                                            <option>HourTo</option>
                                        </select>
                                    </div>

                                    <div>
                                        <select className="uk-select">
                                            <option value="Niveles"></option>

                                            {levels.map(l => (
                                                <option key={l.key} value={l.key}>
                                                    {l.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="uk-width-auto">
                                        <button
                                            type="button"
                                            className="uk-button uk-button-link uk-text-primary">
                                            <Icon path={mdiPlus} size={0.7} />
                                        </button>
                                    </div>
                                </div>

                                {errors?.ASD3 && errors.ASD3.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label">
                                <input
                                    type="checkbox"
                                    name="ASD4"
                                    value="4"
                                    className="uk-checkbox uk-margin-small-right"
                                    onChange={handleChange} />

                                Jueves
                            </label>
                            <div className="uk-form-controls">
                                <div className="uk-child-width-expand uk-grid-small" data-uk-grid>
                                    <div>
                                        <select className="uk-select">
                                            <option>HourFrom</option>
                                        </select>
                                    </div>

                                    <div>
                                        <select className="uk-select">
                                            <option>HourTo</option>
                                        </select>
                                    </div>

                                    <div>
                                        <select className="uk-select">
                                            <option value="Niveles"></option>

                                            {levels.map(l => (
                                                <option key={l.key} value={l.key}>
                                                    {l.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="uk-width-auto">
                                        <button
                                            type="button"
                                            className="uk-button uk-button-link uk-text-primary">
                                            <Icon path={mdiPlus} size={0.7} />
                                        </button>
                                    </div>
                                </div>

                                {errors?.ASD4 && errors.ASD4.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label">
                                <input
                                    type="checkbox"
                                    name="ASD5"
                                    value="5"
                                    className="uk-checkbox uk-margin-small-right"
                                    onChange={handleChange} />

                                Viernes
                            </label>
                            <div className="uk-form-controls">
                                <div className="uk-child-width-expand uk-grid-small" data-uk-grid>
                                    <div>
                                        <select className="uk-select">
                                            <option>HourFrom</option>
                                        </select>
                                    </div>

                                    <div>
                                        <select className="uk-select">
                                            <option>HourTo</option>
                                        </select>
                                    </div>

                                    <div>
                                        <select className="uk-select">
                                            <option value="Niveles"></option>

                                            {levels.map(l => (
                                                <option key={l.key} value={l.key}>
                                                    {l.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="uk-width-auto">
                                        <button
                                            type="button"
                                            className="uk-button uk-button-link uk-text-primary">
                                            <Icon path={mdiPlus} size={0.7} />
                                        </button>
                                    </div>
                                </div>

                                {errors?.ASD5 && errors.ASD5.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label">
                                <input
                                    type="checkbox"
                                    name="ASD6"
                                    value="6"
                                    className="uk-checkbox uk-margin-small-right"
                                    onChange={handleChange} />

                                Sábado
                            </label>
                            <div className="uk-form-controls">
                                <div className="uk-child-width-expand uk-grid-small" data-uk-grid>
                                    <div>
                                        <select className="uk-select">
                                            <option>HourFrom</option>
                                        </select>
                                    </div>

                                    <div>
                                        <select className="uk-select">
                                            <option>HourTo</option>
                                        </select>
                                    </div>

                                    <div>
                                        <select className="uk-select">
                                            <option value="Niveles"></option>

                                            {levels.map(l => (
                                                <option key={l.key} value={l.key}>
                                                    {l.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="uk-width-auto">
                                        <button
                                            type="button"
                                            className="uk-button uk-button-link uk-text-primary">
                                            <Icon path={mdiPlus} size={0.7} />
                                        </button>
                                    </div>
                                </div>

                                {errors?.ASD6 && errors.ASD6.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label">
                                <input
                                    type="checkbox"
                                    name="ASD7"
                                    value="7"
                                    className="uk-checkbox uk-margin-small-right"
                                    onChange={handleChange} />

                                Domingo
                            </label>
                            <div className="uk-form-controls">
                                <div className="uk-child-width-expand uk-grid-small" data-uk-grid>
                                    <div>
                                        <select className="uk-select">
                                            <option>HourFrom</option>
                                        </select>
                                    </div>

                                    <div>
                                        <select className="uk-select">
                                            <option>HourTo</option>
                                        </select>
                                    </div>

                                    <div>
                                        <select className="uk-select">
                                            <option value=""></option>

                                            {levels.map(l => (
                                                <option key={l.key} value={l.key}>
                                                    {l.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="uk-width-auto">
                                        <button
                                            type="button"
                                            className="uk-button uk-button-link uk-text-primary">
                                            <Icon path={mdiPlus} size={0.7} />
                                        </button>
                                    </div>
                                </div>

                                {errors?.ASD7 && errors.ASD7.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>

            <button type="submit" className="uk-button uk-button-primary uk-margin-medium-top" disabled={formSubmitted}>
                {getButtonText()}
            </button>
        </form >
    );
}