import { mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import React, { FormEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Content from "../components/content";
import CourseCardInfo from "../components/courseCardInfo";
import UsePageTitle from "../components/usePageTitle";
import { Constants } from "../constants";
import { iCourseDetails } from "../interfaces/course";
import { iProductDetails } from "../interfaces/product";
import { iSearch } from "../interfaces/search";
import { formatCurrencyMXN } from "../utils";

const initialSearchData: iSearch = {
    key: '',
    type: Constants.SearchType.Product
}

/** Página principal */
export default function Home() {
    const [courses, setCourses] = useState<iCourseDetails[]>([]);
    const [products, setProducts] = useState<iProductDetails[]>([]);
    const [search, setSearch] = useState(initialSearchData);

    const navigate = useNavigate();

    useEffect(() => {
        fetch('/api/Course/GetPopular?total=4')
            .then(res => res.json())
            .then(data => {
                setCourses(data as iCourseDetails[]);
            });

        fetch('/api/Product/GetNewProducts?total=6')
            .then(res => res.json())
            .then(data => {
                setProducts(data as iProductDetails[]);
            });
    }, []);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        const url = `/${search.type}?name=${search.key}`;

        navigate(url);
    }

    UsePageTitle('Lynx Skate House');

    return (
        <>
            <section className="uk-section uk-height-large uk-position-relative uk-background-cover"
                data-src="/img/main-image.jpg" data-uk-img="">
                <div className="uk-position-center" style={{
                    width: '100%',
                    maxWidth: '600px'
                }}>
                    <div className="uk-container">
                        <div className="uk-panel uk-padding-small" style={{ backgroundColor: "#ffffffd6" }}>
                            <search>
                                <form onSubmit={handleSubmit}>
                                    <div className="uk-input-group">
                                        <input
                                            id="key"
                                            name="key"
                                            type="text"
                                            value={search.key}
                                            onChange={(e) => setSearch(prevModel => ({ ...prevModel, key: e.target.value }))}
                                            className="uk-input"
                                            placeholder="Escribe aquí el producto o curso que estas buscando"
                                            required
                                        />
                                        <button type="submit" className="uk-button uk-button-secondary">
                                            <Icon path={mdiMagnify} size={1} />
                                        </button>
                                    </div>
                                    <div className="uk-margin-small uk-grid-small uk-child-width-1-1 uk-child-width-auto@s" data-uk-grid="">
                                        <div>
                                            Buscar en:
                                        </div>
                                        <div>
                                            <label>
                                                <input
                                                    name="type"
                                                    type="radio"
                                                    className="uk-radio"
                                                    checked={search.type == Constants.SearchType.Product}
                                                    required
                                                    onChange={(e) => setSearch(prevModel => ({ ...prevModel, type: e.target.checked ? Constants.SearchType.Product : '' }))}
                                                /> Productos de la Tienda
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                <input
                                                    name="type"
                                                    type="radio"
                                                    className="uk-radio"
                                                    checked={search.type == Constants.SearchType.Course}
                                                    onChange={(e) => setSearch(prevModel => ({ ...prevModel, type: e.target.checked ? Constants.SearchType.Course : '' }))}
                                                /> Cursos de la Academia
                                            </label>
                                        </div>
                                    </div>
                                </form>
                            </search>
                        </div>
                    </div>
                </div>
            </section>

            <Content>
                {
                    courses.length > 0 &&
                    <section className="uk-margin">
                        <h2>
                            Cursos destacados
                        </h2>

                        <div className="uk-grid-match uk-child-width-1-1 uk-child-width-1-2@s" data-uk-grid="">
                            {courses.map(course => (
                                <div key={course.id}>
                                    <CourseCardInfo course={course} />
                                </div>
                            ))}
                        </div>
                    </section>
                }

                {
                    products.length > 0 &&
                    <section className="uk-margin">
                        <h2>
                            Nuevos productos
                        </h2>

                        <div className="uk-slider-container-offset" data-uk-slider>
                            <div className="uk-position-relative uk-visible-toggle uk-dark" data-tabindex="-1">
                                <div className="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-grid">

                                    {products.map(product => (
                                        <div key={product.id}>
                                            <div className="uk-card uk-card-small uk-card-default">
                                                <div className="uk-card-media-top">
                                                    <div className="uk-display-block uk-cover-container uk-height-medium">
                                                        <Link to={`/producto/${product.id}`} className="uk-link-reset">
                                                            <img
                                                                src={product.media && product.media.length > 0 ? `/storage/products/${product.media[0]}` : "/img/no-image.jpg"}
                                                                data-uk-img data-uk-cover
                                                                alt="Imagen del producto" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="uk-card-body">
                                                    <p className="uk-text-bold uk-margin-small-bottom">
                                                        <Link to={`/producto/${product.id}`} className="uk-link-heading">
                                                            {product.name}
                                                        </Link>
                                                    </p>
                                                    <div>
                                                        {product.category}
                                                    </div>
                                                    <div className="uk-text-primary">
                                                        {formatCurrencyMXN(product.price ?? 0)}
                                                    </div>
                                                </div>
                                                <div className="uk-position-top-right uk-background-primary uk-light uk-padding-small">
                                                    NUEVO
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                                <a className="uk-position-center-left uk-position-small" href="" data-uk-slidenav-previous uk-slider-item="previous"></a>
                                <a className="uk-position-center-right uk-position-small" href="" data-uk-slidenav-next uk-slider-item="next"></a>
                            </div>
                        </div>
                    </section>
                }
            </Content>
        </>
    );
}