import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Content from "../../components/content";
import Heading from "../../components/heading";
import Pagination from "../../components/pagination";
import UsePageTitle from "../../components/usePageTitle";
import { iLogIndex } from "../../interfaces/log";
import { iPagination } from "../../interfaces/pagination";
import { formatDate, getPaginationRangeText } from "../../utils";

export default function LogIndex() {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState<iLogIndex | null>(null);
    const [pagination, setPagination] = useState<iPagination | null>(null);
    const [query, setQuery] = useState<string>('');

    useEffect(() => {
        const paginationParams = new URLSearchParams(Object.entries(pagination ?? {}))
        const paginationQueryString = paginationParams.toString();

        let params = '';

        if (paginationQueryString) {
            if (params) {
                params += `&${paginationQueryString}`;
            } else {
                params += paginationQueryString;
            }
        }

        if (location.search != params) {
            setQuery(params);
            navigate({ search: params });
        }
    }, [pagination]);

    useEffect(() => {
        fetch(`/api/Log/Index?${query}`)
            .then(res => res.json())
            .then(data => {
                setData(data as iLogIndex);
            });
    }, [query]);

    useEffect(() => {
        const search = location.search.slice(1);

        if (search != query) {
            setQuery(search);
        }
    }, [location.search])

    const handlePageChange = useCallback((page: number) => {
        setPagination({
            ...pagination,
            currentPage: page
        });
    }, []);

    UsePageTitle('Logs');

    return (
        <Content>
            <Heading text="Logs" />

            <div className="uk-margin">
                <div className="uk-flex uk-flex-between uk-flex-middle">
                    <div>
                        {getPaginationRangeText(data?.pagination?.currentPage, data?.pagination?.itemsPerPage, data?.totalItems)}
                    </div>
                </div>
            </div>

            <div className="uk-margin">
                <div className="uk-card uk-card-small uk-card-default uk-flex uk-flex-column">
                    <ul className="uk-list uk-list-divider uk-margin-remove">
                        {data && data.list && data.list?.length > 0 && data.list.map(log => (
                            <li key={log.id} className="uk-padding-small uk-margin-remove">
                                <div className="uk-grid-small uk-child-width-1-3@s" data-uk-grid>
                                    <div className="uk-width-expand@s">
                                        <div className="uk-link-heading">
                                            <h2 className="uk-h6 uk-margin-remove-vertical">
                                                <Link to={`/logs/${log.id}`} state={{ log }}>
                                                    {log.title}
                                                </Link>
                                            </h2>
                                        </div>
                                        <ul className="uk-subnav uk-margin-xsmall-vertical" data-uk-margin>
                                            {log.userName && <li>
                                                <div>
                                                    <strong>Usuario:</strong> {log.userName}
                                                </div>
                                            </li>}
                                            <li>
                                                <div>
                                                    <strong>Nivel:</strong> {log.level}
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <strong>Fecha:</strong> {formatDate(log.createdTime, true)}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {(data?.totalPages ?? 0) > 1 && <div className="uk-margin">
                <Pagination
                    currentPage={pagination?.currentPage ?? 1}
                    totalPages={data?.totalPages ?? 1}
                    onPageChange={handlePageChange}
                />
            </div>}
        </Content>
    );
}