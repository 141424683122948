import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UIkit from "uikit";
import AddressForm from "../../components/addressForm";
import Content from "../../components/content";
import Heading from "../../components/heading";
import UsePageTitle from "../../components/usePageTitle";
import { iAddressBase, iAddressForm } from "../../interfaces/address";
import { iStatus } from "../../interfaces/status";

/** Página que muestra el formulario para editar una ubicación */
export default function EditAddress() {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

    const handleSubmit = useCallback((addressData: iAddressForm, addressBaseData: iAddressBase) => {
        const model = {
            ...addressData,
            ...addressBaseData
        }

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(model)
        };

        setFormSubmitted(true);

        fetch(`/api/Admin/EditAddress/${id}`, requestOptions)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                const status = data as iStatus

                setErrors({});

                if (status.success) navigate(-1);

                status.messages?.forEach(mesagge => {
                    UIkit.notification({
                        message: `${mesagge}`,
                        status: status.success ? 'success' : 'danger',
                        pos: 'top-center'
                    });
                });

                if (status.errors) setErrors(data.errors);
            })
            .finally(() => {
                setFormSubmitted(false);
            });
    }, [])

    UsePageTitle('Editar Ubicación');

    return (
        <Content>
            <Heading text="Editar Ubicación" backOption={-1} />

            <div>
                <AddressForm
                    id={id}
                    errors={errors}
                    onSubmit={handleSubmit}
                    formSubmitted={formSubmitted}
                />
            </div>
        </Content>
    );
}