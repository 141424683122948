import { mdiCashCheck } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UIkit from "uikit";
import Content from "../../components/content";
import Heading from "../../components/heading";
import Pagination from "../../components/pagination";
import SubscriptionStatusLabel from "../../components/subscriptionStatusLabel";
import UsePageTitle from "../../components/usePageTitle";
import { Constants } from "../../constants";
import { iPagination } from "../../interfaces/pagination";
import { iStatus } from "../../interfaces/status";
import { iSubscriptionIndex } from "../../interfaces/subscription";
import { formatDate, getPaginationRangeText } from "../../utils";

/** Página para administrar las suscripciones del sitio */
export default function SiteSubscriptionManage() {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState<iSubscriptionIndex | null>(null);
    const [pagination, setPagination] = useState<iPagination | null>(null);
    const [query, setQuery] = useState<string>('');

    useEffect(() => {
        const paginationParams = new URLSearchParams(Object.entries(pagination ?? {}))

        const paginationQueryString = paginationParams.toString();

        let params = '';

        if (paginationQueryString) {
            if (params) {
                params += `&${paginationQueryString}`;
            } else {
                params += paginationQueryString;
            }
        }

        if (location.search != params) {
            setQuery(params);
            navigate({ search: params });
        }
    }, [pagination]);

    useEffect(() => {
        fetch(`/api/ClassCapturist/SiteSubscription/Index?${query}`)
            .then(res => res.json())
            .then(data => {
                setData(data as iSubscriptionIndex);
            });
    }, [query]);

    useEffect(() => {
        const search = location.search.slice(1);

        if (search != query) {
            setQuery(search);
        }
    }, [location.search])

    const handlePageChange = useCallback((page: number) => {
        setPagination({
            ...pagination,
            currentPage: page
        });
    }, []);

    const handlePaySiteSubscription = (siteSubscriptioId: number) => {
        UIkit.modal.confirm(`<p>¿Estás seguro de que deseas marcar como pagada la suscripción al sitio #${siteSubscriptioId}?</p>`).then(function () {
            fetch(`/api/ClassCapturist/SiteSubscription/Pay/${siteSubscriptioId}`)
                .then(res => {
                    if (!res.ok) {
                        window.location.href = `${window.location.origin}/Error`;
                    }

                    return res.json()
                })
                .then(result => {
                    const status = result as iStatus

                    if (status.success && data && data.list && data.list?.length > 0) {
                        const updatedList = data.list.map(item => {
                            if (item.id === siteSubscriptioId) {
                                return { ...item, status: Constants.SubscriptionStatus.Active };
                            }

                            return item;
                        });

                        setData({
                            ...data,
                            list: updatedList
                        })
                    }

                    status.messages?.forEach(mesagge => {
                        UIkit.notification({
                            message: `${mesagge}`,
                            status: status.success ? 'success' : 'danger',
                            pos: 'top-center'
                        });
                    });
                });
        }, function () {
            // nothing to do...
        })
    }

    UsePageTitle('Administración de suscripciones');

    return (
        <Content>
            <Heading text="Administración de suscripciones" />

            <section className="uk-section uk-section-xsmall">
                <div className="uk-flex uk-flex-between uk-flex-middle">
                    <div>
                        {getPaginationRangeText(data?.pagination?.currentPage, data?.pagination?.itemsPerPage, data?.totalItems)}
                    </div>
                </div>
            </section>

            {data && data.list && data.list?.length > 0 && <>
                <section className="uk-section uk-section-xsmall">
                    <div className="uk-card uk-card-small uk-card-default uk-flex uk-flex-column">
                        <ul className="uk-list uk-list-divider uk-margin-remove">
                            {data.list.map(subscription => (
                                <li key={subscription.id} className="uk-padding-small uk-margin-remove">
                                    <div className="uk-grid-small uk-child-width-1-3@s" data-uk-grid>
                                        <div className="uk-width-expand@s">
                                            <div data-uk-margin>
                                                <SubscriptionStatusLabel status={subscription.status} />
                                                <h2 className="uk-h6 uk-margin-remove-vertical">
                                                    {subscription.createdByName}
                                                    <span className="uk-margin-small-left">
                                                        #{subscription.id}
                                                    </span>
                                                </h2>
                                            </div>
                                            {(subscription.dateOfPay || subscription.dueDate) && <ul className="uk-subnav uk-margin-xsmall-vertical" data-uk-margin>
                                                {subscription.dateOfPay && <li>
                                                    <div>
                                                        <strong>Fecha de Pago:</strong> {formatDate(subscription.dateOfPay)}
                                                    </div>
                                                </li>}
                                                {subscription.dueDate && <li>
                                                    <div>
                                                        <strong>Vigencia:</strong> {formatDate(subscription.dueDate)}
                                                    </div>
                                                </li>}
                                            </ul>}
                                        </div>
                                        {subscription.status == Constants.SubscriptionStatus.PendingPayment && <>
                                            <div className="uk-width-auto@s">
                                                <button
                                                    className="uk-button uk-button-link"
                                                    title="Marcar como pagada"
                                                    data-uk-tooltip=""
                                                    onClick={() => handlePaySiteSubscription(subscription.id)}
                                                >
                                                    <Icon path={mdiCashCheck} size={.90} />
                                                </button>
                                            </div>
                                        </>}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </section>
            </>}

            {(data?.totalPages ?? 0) > 1 && <div className="uk-margin">
                <Pagination
                    currentPage={pagination?.currentPage ?? 1}
                    totalPages={data?.totalPages ?? 1}
                    onPageChange={handlePageChange}
                />
            </div>}
        </Content>
    );
}