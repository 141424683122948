import { mdiCashCheck, mdiCloseCircle } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import UIkit from "uikit";
import Content from "../../components/content";
import Heading from "../../components/heading";
import TextToHtml from "../../components/textToHtml";
import UsePageTitle from "../../components/usePageTitle";
import { CLASSCAPTURIST, Constants } from "../../constants";
import { iStatus } from "../../interfaces/status";
import { iSubscriptionDetails } from "../../interfaces/subscription";
import { useUser } from "../../userContext";
import { formatConjunction, formatCurrencyMXN, formatDate } from "../../utils";

/**  Página que muestra los detalles de una suscripción */
export default function SubscriptionDetails() {
    const { user } = useUser();

    const { state } = useLocation() as {
        state: { subscription: iSubscriptionDetails, isFromManagePage?: boolean } | null;
    };

    const { id } = useParams<{ id: string }>();
    const [subscription, setSubscription] = useState<iSubscriptionDetails | null>(null);
    const [showCancelButton, setShowCancelButton] = useState<boolean>(false);
    const [showPayButton, setShowPayButton] = useState(false);

    useEffect(() => {
        if (state?.subscription) {
            setSubscription(state.subscription);

            if (user?.roles.includes(CLASSCAPTURIST) && state?.isFromManagePage && state.subscription.status == Constants.SubscriptionStatus.PendingPayment) {
                setShowPayButton(true);
            } else {
                setShowPayButton(false);
            }

            if (user?.roles.includes(CLASSCAPTURIST) && state.isFromManagePage && !state.subscription.cancelled) {
                setShowCancelButton(true);
            } else if (!state.isFromManagePage && state.subscription.status == Constants.SubscriptionStatus.PendingPayment) {
                setShowCancelButton(true);
            } else {
                setShowCancelButton(false);
            }
        } else {
            fetch(`/api/Me/Subscription/Details/${id}`)
                .then(res => {
                    if (!res.ok) {
                        window.location.href = `${window.location.origin}/Error`;
                    }

                    return res.json()
                })
                .then(data => {
                    const result = data as iSubscriptionDetails;

                    setSubscription(result);

                    if (user?.roles.includes(CLASSCAPTURIST) && state?.isFromManagePage && result.status == Constants.SubscriptionStatus.PendingPayment) {
                        setShowPayButton(true);
                    } else {
                        setShowPayButton(false);
                    }

                    if (user?.roles.includes(CLASSCAPTURIST) && state?.isFromManagePage && !result.cancelled) {
                        setShowCancelButton(true);
                    } else if (!state?.isFromManagePage && result.status == Constants.SubscriptionStatus.PendingPayment) {
                        setShowCancelButton(true);
                    } else {
                        setShowCancelButton(false);
                    }
                });
        }
    }, [id, state]);

    const handleCancelSubscription = () => {
        UIkit.modal.confirm('<p>¿Estás seguro de que deseas cancelar la suscripción?</p>').then(function () {
            fetch(`/api/Me/Subscription/Cancel/${id}`)
                .then(res => {
                    if (!res.ok) {
                        window.location.href = `${window.location.origin}/Error`;
                    }

                    return res.json()
                })
                .then(data => {
                    const status = data as iStatus

                    status.messages?.forEach(mesagge => {
                        UIkit.notification({
                            message: `${mesagge}`,
                            status: status.success ? 'success' : 'danger',
                            pos: 'top-center'
                        });
                    });

                    // Oculta el boton cuando se cancela la suscripción ya que despues de esta acción no se obtiene de nuevo los datos y se seguiría mostrando dicho boton.
                    if (status.success) {
                        setShowCancelButton(false);
                        setShowPayButton(false);
                    }
                });
        }, function () {
            // nothing to do...
        })
    }

    const handlePaySubscription = () => {
        UIkit.modal.confirm('<p>¿Estás seguro de que deseas marcar como pagada la suscripción?</p>').then(function () {
            fetch(`/api/ClassCapturist/Subscription/PayFromManager/${id}`)
                .then(res => {
                    if (!res.ok) {
                        window.location.href = `${window.location.origin}/Error`;
                    }

                    return res.json()
                })
                .then(data => {
                    const status = data as iStatus

                    status.messages?.forEach(mesagge => {
                        UIkit.notification({
                            message: `${mesagge}`,
                            status: status.success ? 'success' : 'danger',
                            pos: 'top-center'
                        });
                    });

                    // Oculta el boton cuando se paga la suscripción ya que despues de esta acción no se obtiene de nuevo los datos y se seguiría mostrando dicho boton.
                    if (status.success) setShowPayButton(false);
                });
        }, function () {
            // nothing to do...
        })
    }

    UsePageTitle('Detalles de la suscripción');

    return (
        <Content>
            <Heading text={subscription?.course?.title ?? ""} backOption={state?.isFromManagePage ? "/administrar-clases" : "/suscripciones"} />

            <div className="uk-margin-large-bottom">
                <div className="uk-child-width-1-1 uk-child-width-1-2@m" data-uk-grid>
                    <section>
                        <div className="uk-flex uk-flex-row@m" data-uk-margin>
                            <span className="uk-width-1-3@s uk-width-1-4@m">
                                Número de registro:
                            </span>
                            <span>
                                <strong>
                                    #{subscription?.id}
                                </strong>
                            </span>
                        </div>
                        <div className="uk-flex uk-flex-row@m" data-uk-margin>
                            <span className="uk-width-1-3@s uk-width-1-4@m">
                                Fecha de registro:
                            </span>
                            <span>
                                <strong>
                                    {formatDate(subscription?.createdTime, true)}
                                </strong>
                            </span>
                        </div>
                    </section>

                    <section>
                        <h2>
                            Clases y vigencia
                        </h2>

                        <div className="uk-flex uk-flex-row@m" data-uk-margin>
                            <span className="uk-width-1-3@s uk-width-1-4@m">
                                {subscription?.acquiredClasses ? 'Precio por clase:' : 'Precio:'}
                            </span>
                            <span>
                                <strong>
                                    {subscription?.acquiredClasses ? formatCurrencyMXN(subscription.price / subscription.acquiredClasses) : formatCurrencyMXN(subscription?.price ?? 0)}
                                </strong>
                            </span>
                        </div>

                        {subscription?.acquiredClasses && <div className="uk-flex uk-flex-row@m" data-uk-margin>
                            <span className="uk-width-1-3@s uk-width-1-4@m">
                                Clases adquiridas:
                            </span>
                            <span>
                                <strong>
                                    {subscription.acquiredClasses}&nbsp;
                                </strong>
                                ({subscription.pendingClasses} restantes)
                            </span>
                        </div>}

                        {subscription?.dueDate && <div className="uk-flex uk-flex-row@m" data-uk-margin>
                            <span className="uk-width-1-3@s uk-width-1-4@m">
                                Vigencia:
                            </span>
                            <span>
                                <strong>
                                    {formatDate(subscription.dueDate, true)}
                                </strong>
                            </span>
                        </div>}

                        {!state?.isFromManagePage && <>
                            <Link
                                to="/academia"
                                className="uk-button uk-button-primary uk-button-small uk-margin-small-top">
                                Ver más cursos
                            </Link>
                        </>}
                    </section>

                    <section>
                        <h2>
                            Alumnos registrados
                        </h2>

                        {subscription && subscription.participants && subscription.participants.length > 0 && subscription.participants.map((participant, index) => (
                            <div key={participant.id} className={index > 0 ? 'uk-margin-medium-top' : ''}>
                                <div className="uk-flex uk-flex-row@m" data-uk-margin>
                                    <span className="uk-width-1-3@s uk-width-1-4@m">
                                        Nombre:
                                    </span>
                                    <span>
                                        <strong>
                                            {participant.name}
                                        </strong>
                                    </span>
                                </div>
                                <div className="uk-flex uk-flex-row@m" data-uk-margin>
                                    <span className="uk-width-1-3@s uk-width-1-4@m">
                                        Fecha de nacimiento:
                                    </span>
                                    <span>
                                        <strong>
                                            {formatDate(participant.birthdate)}
                                        </strong>
                                    </span>
                                </div>
                                {participant.relationship && <div className="uk-flex uk-flex-row@m" data-uk-margin>
                                    <span className="uk-width-1-3@s uk-width-1-4@m">
                                        Parentesco:
                                    </span>
                                    <span>
                                        <strong>
                                            {participant.relationship}
                                        </strong>
                                    </span>
                                </div>}
                                {participant.parentName && <div className="uk-flex uk-flex-row@m" data-uk-margin>
                                    <span className="uk-width-1-3@s uk-width-1-4@m">
                                        Nombre del Padre, Madre o Tutor:
                                    </span>
                                    <span className="uk-flex uk-flex-bottom">
                                        <strong>
                                            {participant.parentName}
                                        </strong>
                                    </span>
                                </div>}

                                {/*<Link*/}
                                {/*    to={`/participante/${participant.id}`}*/}
                                {/*    className="uk-button uk-button-primary uk-button-small uk-margin-small-top">*/}
                                {/*    Ver Avance  <Icon path={mdiArrowRight} size={1} />*/}
                                {/*</Link>*/}
                            </div>
                        ))}
                    </section>

                    <section>
                        <h2>
                            Información general
                        </h2>
                        <p>
                            <TextToHtml text={subscription?.course?.description ?? ''} />
                        </p>
                        <p>
                            <strong>Niveles: </strong>
                            <span>
                                {formatConjunction(subscription?.course?.levels ?? [])}
                            </span>
                        </p>
                        <p>
                            <strong>Diciplinas: </strong>
                            <span>
                                {formatConjunction(subscription?.course?.disciplines ?? [])}
                            </span>
                        </p>
                        <p>
                            <strong>Beneficios: </strong>
                            {formatConjunction(subscription?.course?.benefits.split('\n') ?? [])}
                        </p>
                        <p>
                            <strong>Requisitos: </strong>
                            <span>
                                {formatConjunction(subscription?.course?.requirements ?? [])}
                            </span>
                        </p>
                    </section>
                </div>
            </div>

            {showCancelButton && <>
                <button
                    className="uk-button uk-button-link uk-margin-medium-bottom uk-text-primary"
                    onClick={handleCancelSubscription}
                >
                    <div className="uk-flex uk-flex-middle">
                        <Icon path={mdiCloseCircle} size={.75} />
                        <span>
                            Cancelar curso
                        </span>
                    </div>
                </button>
            </>}

            {showPayButton && <>
                <button
                    className={`uk-button uk-button-link uk-margin-medium-bottom uk-text-primary ${showCancelButton ? 'uk-margin-left' : ''}`}
                    onClick={handlePaySubscription}
                >
                    <div className="uk-flex uk-flex-middle">
                        <Icon path={mdiCashCheck} size={.90} />
                        <span>
                            Marcar como pagado
                        </span>
                    </div>
                </button>
            </>}
        </Content>
    );
}