import React, { FormEvent, useEffect, useRef, useState } from "react";
import { iSelectOption } from "../interfaces/selectOption";
import { iUserForm } from "../interfaces/user";
import { translateRoles } from "../utils";
import { iTomSelectOption } from "./tomSelect";

/** Interfaz con las propiedades para el formulario de un usuario */
interface iCourseFormProps {
    /** Id del usuario. Se manda en el caso de la edición */
    id?: string;

    /** Modelo que maneja los errores. Sus propiedades se manejan en PascalCase ya que dependen de los modelos en el back */
    errors: { [key: string]: string[] };

    /** Función para manejar el submit */
    onSubmit: (userData: iUserForm) => void;

    /** Indica si se hizo submit */
    formSubmitted: boolean;
}

/** Datos iniciales para el usuario */
const defaultCourse: iUserForm = {
    name: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    status: '',
    roles: []
}

/** Componente con el formulario de un usuario */
export default function UserForm({ id, errors, onSubmit, formSubmitted }: iCourseFormProps) {
    const [userModel, setUserModel] = useState<iUserForm>(defaultCourse);
    const [formData, setFormData] = useState<{ roles: iSelectOption[], status: iTomSelectOption[] }>();
    const [hiddenPassword, setHiddenPassword] = useState(id !== undefined); // Si se edita el registro por defecto se ocultan los inputs de password.

    /** 
     * estado del componente 
     * @description utiliza esta variable para determinar cual es el estado actual del componente
     * */
    const state = useRef<"" | "loading" | "loaded" | "saving" | "done">("");

    /** 
     * id del usuario 
     * @description utiliza esta variable para determinar si el usuario ha cambiado entre renderizados
     * */
    const idRef = useRef<string>("");

    /** 
     * url donde se despliega el componente 
     * @description utiliza esta variable para determinar si el componente a cambiado de página
     * */
    const href = useRef<string>();

    const getUserFormData = () => {
        if (state.current != "loading") return;

        fetch(`/api/Admin/User/GetFormData/${idRef.current}`)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                const user = data as iUserForm;
                setUserModel(user || []);
            });
    }

    const getFormData = () => {
        const process = [
            fetch('/api/Admin/User/GetRoles')
                .then(res => res.json())
                .then(data => data?.map((item: iSelectOption) => {
                    return {
                        key: item.key,
                        value: item.value
                    }
                })),
            fetch('/api/Admin/User/GetStatus')
                .then(res => res.json())
                .then(data => data?.map((item: iSelectOption): iTomSelectOption => {
                    return {
                        text: item.key,
                        value: item.value
                    }
                }))
        ];

        Promise.all(process).then(result => {
            setFormData(() => {
                state.current = "loaded";

                return {
                    roles: result[0],
                    status: result[1],
                };
            });
        });
    }

    useEffect(() => getFormData(), [userModel]);

    useEffect(() => {
        if (href.current != location.href) {
            state.current = "";
            href.current = location.href;
        }
    }, [location.href]);

    useEffect(() => {
        const newId = id || "";

        if (newId == idRef.current) {
            if (!newId) { // si no hay id, es un nuevo registro
                if (state.current == "") {
                    state.current = "loading";
                    getFormData();
                }
            }
        } else {
            idRef.current = newId;
            state.current = "loading";

            if (!newId) {
                getFormData();
            } else {
                getUserFormData();
            }
        }
    }, []);

    const getButtonText = () => {
        if (id) {
            return formSubmitted ? 'Actualizando...' : 'Actualizar';
        } else {
            return formSubmitted ? 'Registrando...' : 'Registrar';
        }
    };

    /** Función que maneja el cambio de los inputs */
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        setUserModel(prevUserModel => ({
            ...prevUserModel,
            [name]: value
        }));
    };

    /** Función que maneja el cambio de los inputs tipo checkbox */
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = e.target;

        setUserModel((prevUserModel) => {
            const selectedValues = checked
                ? [...prevUserModel[name as keyof iUserForm] as string[], value]
                : (prevUserModel[name as keyof iUserForm] as string[]).filter(v => v !== value);

            return {
                ...prevUserModel,
                [name]: selectedValues,
            };
        });
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        onSubmit(userModel);
    }

    if (idRef && (state.current == "" || state.current == "loading")) {
        return "Obteniendo información...";
    }
    return (
        <form onSubmit={handleSubmit} className="uk-form-stacked">
            <div className="uk-child-width-1-1 uk-child-width-1-2@s" data-uk-grid>
                <fieldset className="uk-fieldset" disabled={formSubmitted}>
                    <div className="uk-form-horizontal">
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="name">Nombre:</label>
                            <div className="uk-form-controls">
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={userModel.name}
                                    className="uk-input"
                                    onChange={handleInputChange}
                                    autoComplete="name"
                                    required />

                                {errors?.Name && errors.Name.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="lastName">Apellidos:</label>
                            <div className="uk-form-controls">
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={userModel.lastName}
                                    className="uk-input"
                                    onChange={handleInputChange}
                                    autoComplete="family-name" />

                                {errors?.LastName && errors.LastName.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="email">E-Mail:</label>
                            <div className="uk-form-controls">
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={userModel.email}
                                    className="uk-input"
                                    onChange={handleInputChange}
                                    autoComplete="email"
                                    required />

                                {errors?.Email && errors.Email.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="status">Estatus:</label>
                            <div className="uk-form-controls">

                                {/*<TomSelect*/}
                                {/*    id="status"*/}
                                {/*    name="status"*/}
                                {/*    options={formData?.status}*/}
                                {/*    items={[userModel.status]}*/}
                                {/*    onValueChange={(value) => handleSelectChange("status", value[0] || '')}*/}
                                {/*    required*/}
                                {/*/>*/}

                                <select
                                    id="status"
                                    name="status"
                                    value={userModel.status}
                                    onChange={handleInputChange}
                                    className="uk-select"
                                >
                                    {formData?.status.map(s => (
                                        <option
                                            key={s.value}
                                            value={s.value}
                                        >
                                            {s.text}
                                        </option>
                                    ))}
                                </select>

                                {errors?.Status && errors.Status.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div hidden={id === undefined} className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="changePassword">Cambiar contraseña:</label>
                            <div className="uk-form-controls">
                                <input
                                    type="checkbox"
                                    id="changePassword"
                                    checked={!hiddenPassword}
                                    onChange={() => setHiddenPassword(!hiddenPassword)}
                                    className="uk-checkbox"
                                />
                            </div>
                        </div>
                        <div hidden={hiddenPassword} className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="password">Contraseña:</label>
                            <div className="uk-form-controls">
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    autoComplete="new-password"
                                    value={userModel.password ?? ''}
                                    onChange={handleInputChange}
                                    className="uk-input"
                                    required={!hiddenPassword} />

                                {errors?.Password && errors.Password.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div hidden={hiddenPassword} className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="confirmPassword">Confirma tu contraseña:</label>
                            <div className="uk-form-controls">
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    autoComplete="new-password"
                                    value={userModel.confirmPassword ?? ''}
                                    onChange={handleInputChange}
                                    className="uk-input"
                                    required={!hiddenPassword} />

                                {errors?.ConfirmPassword && errors.ConfirmPassword.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset className="uk-fieldset" disabled={formSubmitted}>
                    <div className="uk-form-horizontal">
                        <div className="uk-margin-small">
                            <label className="uk-form-label">Roles:</label>
                            <div className="uk-form-controls">
                                <ul className="uk-list">
                                    {formData?.roles.map(role => (
                                        <li key={role.value}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="roles"
                                                    value={role.value}
                                                    className="uk-checkbox uk-margin-small-right"
                                                    checked={userModel.roles.includes(role.value)}
                                                    onChange={handleCheckboxChange}
                                                />

                                                {translateRoles([role.key])}
                                            </label>
                                        </li>
                                    ))}
                                </ul>

                                {errors?.Roles && errors.Roles.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>

            <button type="submit" className="uk-button uk-button-primary uk-margin-medium-top" disabled={formSubmitted}>
                {getButtonText()}
            </button>
        </form >
    );
}