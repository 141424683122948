import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";

/** Interfaz con las propiedades para una páginación */
interface iPaginationProps {
    /** Página actual */
    currentPage: number;

    /** Páginas totales a mostrar */
    totalPages: number;

    /** Función para manejar la paginación */
    onPageChange: (page: number) => void;
}

/**
 * Componente que muestra una paginación
 */
export default function Pagination({ currentPage, totalPages, onPageChange }: iPaginationProps) {
    const generatePages = () => {
        const maxPagesToShow = 10;
        const pages: (number | '...')[] = [];

        if (totalPages <= maxPagesToShow) {
            return Array.from({ length: totalPages }, (_, index) => index + 1);
        }

        const startPages = [1, 2];
        const endPages = [totalPages - 1, totalPages];
        const middleRange = 4;

        if (currentPage <= middleRange + 2) {
            pages.push(...Array.from({ length: 6 }, (_, index) => index + 1), '...', ...endPages);
        } else if (currentPage >= totalPages - middleRange - 1) {
            pages.push(...startPages, '...', ...Array.from({ length: 6 }, (_, index) => totalPages - 5 + index));
        } else {
            pages.push(
                ...startPages,
                '...',
                ...Array.from({ length: 5 }, (_, index) => currentPage - 2 + index),
                '...',
                ...endPages
            );
        }

        return pages;
    };

    const handlePageChange = (page: number | string) => {
        if (typeof (page) == "string") {
            return;
        }

        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    const pages = generatePages();

    return (
        <nav aria-label="Paginación">
            <ul className="uk-pagination uk-flex-center">
                <li>
                    <button
                        className="uk-button uk-button-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage == 1}
                    >
                        <Icon path={mdiChevronLeft} size={1} /> Anterior
                    </button>
                </li>
                {pages.map((page, i) => (
                    <li key={typeof (page) == 'string' ? page + i : page} className={page == currentPage ? 'uk-active' : ''}>
                        {typeof (page) == 'string'
                            ?
                            <span className="uk-text-muted">
                                {page}
                            </span>
                            :
                            <button
                                className="uk-button uk-button-link"
                                onClick={() => handlePageChange(page)}
                                disabled={currentPage == page}
                            >
                                {page}
                            </button>
                        }

                    </li>
                ))}
                <li>
                    <button
                        className="uk-button uk-button-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage == totalPages}
                    >
                        Siguiente <Icon path={mdiChevronRight} size={1} />
                    </button>
                </li>
            </ul>
        </nav>
    );
}
