import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { Constants } from "../../constants";

/** Página que a mostrar cuando un pago es declinado. */
export default function DeclinedPayment() {
    const { subscriptionId } = useParams<{ subscriptionId: string }>();

    const [code, setCode] = useState('');
    const [message, setMessage] = useState('');

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const params: any = Object.fromEntries(searchParams.entries());

        setCode(params.code);
        setMessage(params.message);
    }, [searchParams]);

    const getCodeType = (code: string) => {
        let text = '';

        if (code === Constants.PaymentResponseCode.Declined) {
            text = 'Declinada';
        } else if (code === Constants.PaymentResponseCode.Refused) {
            text = 'Rechazado';
        } else if (code === Constants.PaymentResponseCode.NotAnswered) {
            text = 'No respondida';
        }

        return text;
    }

    return (
        <div className="uk-width-expand uk-width-large@s uk-margin-auto">
            <div className="uk-text-center">
                <div>
                    <span className="mdi mdi-emoticon-sad-outline mdi-48px"></span>
                </div>
                <h2 className="uk-margin-remove">
                    {getCodeType(code)}
                </h2>
                <p>
                    {message}
                </p>
                <p>
                    <Link
                        to={`/suscripcion/${subscriptionId}/informacion-de-pago`}
                        className="uk-button uk-button-default"
                    >
                        Volver a intentar
                    </Link>
                </p>
                <p>
                    <Link
                        to={`/suscripcion/${subscriptionId}/confirmacion-de-registro`}
                        className="uk-button uk-button-default"
                    >
                        Continuar y pagar después
                    </Link>
                </p>
            </div>
        </div>
    );
}