import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { iCourseFilters, iCourseIndex } from "../interfaces/course";
import { iPagination } from "../interfaces/pagination";
import { getPaginationRangeText } from "../utils";
import CourseCardInfo from "./courseCardInfo";
import Pagination from "./pagination";

interface iCourseIndexProps {
    /** Indica si el componente se esta viendo desde la administración */
    isFromManagePage?: boolean;
}

/** Index de los cursos */
export default function CourseIndex({ isFromManagePage = false }: iCourseIndexProps) {
    const [data, setData] = useState<iCourseIndex | null>(null);
    const [pagination, setPagination] = useState<iPagination | null>(null);
    const [filters, setFilters] = useState<iCourseFilters | null>(null);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const params: any = Object.fromEntries(searchParams.entries());

        setFilters({
            name: params.name || '',
            benefits: params.name || '',
            singlePayment: params.name || null,
            classFrom: params.classFrom || null,
            classTo: params.classTo || null,
            price: params.price || null,
            levels: params.levels || null,
            disciplines: params.disciplines || null,
            requirements: params.requirements || null,
        });

        setPagination({
            itemsPerPage: Number(params.itemsPerPage) || undefined,
            currentPage: Number(params.currentPage) || undefined,
            orderBy: params.orderBy,
            orderMode: params.orderModes
        });

        fetchData()
    }, [searchParams]);

    const fetchData = () => {
        const queryString = searchParams.toString();

        fetch(`/api/Course/GetCourseIndex?${queryString}`)
            .then(res => res.json())
            .then(data => {
                setData(data as iCourseIndex);
            });
    }

    const handlePageChange = useCallback((page: number) => {
        const updatedPagination = { ...pagination, currentPage: page };

        // NOTE: No se realiza la actualización del modelo "Pagination" aquí ya que al cambiar "SearchParams" se realizá dicha actualización.
        //setPagination(updatedPagination);

        setSearchParams(prev => {
            const params = new URLSearchParams(prev);

            Object.entries(updatedPagination).forEach(([key, value]) => {
                if (value === undefined || value === "") {
                    params.delete(key);
                } else {
                    params.set(key, String(value));
                }
            });

            return params;
        });
    }, [pagination]);

    const handleFilterChange = (newFilters: iCourseFilters) => {
        // NOTE: No se realiza la actualización del modelo "Filters" aquí ya que al cambiar "SearchParams" se realizá dicha actualización.
        //setFilters(newFilters);

        setSearchParams(prev => {
            const params = new URLSearchParams(prev);

            Object.entries(newFilters).forEach(([key, value]) => {
                if (value === undefined || value === "") {
                    params.delete(key);
                } else {
                    params.set(key, String(value));
                }
            });

            params.set("page", "1");

            return params;
        });
    }

    //const handleMultipleSelectFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    //    const { name, selectedOptions } = e.target;
    //    const selectedValues = Array.from(selectedOptions, (option) => option.value);

    //    setFilters({
    //        ...filters,
    //        [name]: selectedValues,
    //    });
    //};

    return (
        <>
            <div className="uk-margin">
                <div className="uk-flex uk-flex-between uk-flex-middle">
                    <div>
                        {getPaginationRangeText(data?.pagination?.currentPage, data?.pagination?.itemsPerPage, data?.totalItems)}
                    </div>
                    {/*<div className="uk-grid uk-grid-small">*/}
                    {/*    <select className="uk-select uk-form-width-medium" aria-label="Seleccionar la opción de ordenamiento">*/}
                    {/*        <option>1</option>*/}
                    {/*        <option>2</option>*/}
                    {/*        <option>3</option>*/}
                    {/*    </select>*/}
                    {/*    <div>*/}
                    {/*        <button className="uk-button uk-button-primary">*/}
                    {/*            <Icon path={mdiFilterVariant} size={1} /> Filtros*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>

            <div className="uk-margin">
                <div className="uk-child-width-1-1" data-uk-grid>
                    {data && data.list && data.list?.length > 0 ? (
                        data.list.map(course => (
                            <div key={course.id}>
                                <CourseCardInfo course={course} isFromManagePage={isFromManagePage} />
                            </div>
                        ))
                    ) : (
                        <p>No hay clases disponibles.</p>
                    )}
                </div>
            </div>

            {(data?.totalPages ?? 0) > 1 && <div className="uk-margin">
                <Pagination
                    currentPage={pagination?.currentPage ?? 1}
                    totalPages={data?.totalPages ?? 1}
                    onPageChange={handlePageChange}
                />
            </div>}
        </>
    );
}