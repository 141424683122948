import { mdiArrowRightCircleOutline, mdiLink, mdiWhatsapp } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import UIkit from "uikit";
import Breadcrumb from "../../components/breadcrumb";
import Content from "../../components/content";
import Heading from "../../components/heading";
import TextToHtml from "../../components/textToHtml";
import UsePageTitle from "../../components/usePageTitle";
import { CLASSCAPTURIST } from "../../constants";
import { iBreadcrumbItem } from "../../interfaces/breadcrumb";
import { iCourseDetails } from "../../interfaces/course";
import { useUser } from "../../userContext";
import { formatConjunction, formatCurrencyMXN } from "../../utils";

/** Página para ver los detalletes un curso */
export default function CourseDetails() {
    const { user } = useUser();
    const [course, setCourse] = useState<iCourseDetails | null>(null);
    const { id } = useParams<{ id: string }>();

    const { state } = useLocation() as {
        state: { isFromManagePage?: boolean } | null;
    };

    useEffect(() => {
        fetch(`/api/Course/Details/${id}`)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                setCourse(data as iCourseDetails);
            });
    }, [id]);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(window.location.href);

        UIkit.notification({
            message: 'Enlace copiado',
            status: 'success',
            pos: 'top-center'
        });
    }

    const breadcrumbItems: iBreadcrumbItem[] = [
        { path: '/', text: 'Inicio' },
        { path: '/academia', text: 'Academia' },
        { path: `/clase/${course?.id}`, text: `${course?.title}` }
    ];

    UsePageTitle(`Detalles de la clase: ${course?.title}`);

    return (
        <>
            <Content>
                {state?.isFromManagePage && user?.roles.some(role => role == CLASSCAPTURIST)
                    ? <Heading text={`${course?.title}`} backOption={state.isFromManagePage ? "/administrar-cursos" : "/academia"} editOption={`/clase/${id}/editar`} deleteOption={`/api/ClassCapturist/DeleteCourse/${id}`} />
                    : <Heading text={`${course?.title}`} />}

                {!state?.isFromManagePage && <section>
                    <Breadcrumb items={breadcrumbItems} />
                </section>}

                <div className="uk-child-width-1-1 uk-child-width-1-2@s" data-uk-grid>
                    <div>
                        {
                            course?.media && course?.media.length > 0 ? (
                                <div className="uk-position-relative uk-light"
                                    data-uk-slideshow="ratio: 1:1">
                                    <div className="uk-slideshow-items" data-uk-lightbox="">
                                        {
                                            course.media.map(m => (
                                                <a href={`/storage/courses/${m}`} key={m}>
                                                    <img src={`/storage/courses/${m}`} alt="" className="uk-width-1-1 uk-height-1-1 uk-object-contain" />
                                                </a>
                                            ))
                                        }
                                    </div>
                                    <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="" uk-slidenav-previous="" uk-slideshow-item="previous"></a>
                                    <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="" uk-slidenav-next="" uk-slideshow-item="next"></a>
                                </div>
                            ) : (
                                <div>
                                    <img src="/img/no-image.jpg" className="uk-width-1-1" />
                                </div>
                            )
                        }
                    </div>

                    <div>
                        <div className="uk-card uk-card-default">
                            {/*<div className="uk-card-header">*/}
                            {/*    <h2 className="uk-card-title uk-margin-remove-bottom">*/}
                            {/*        {course?.title}*/}
                            {/*    </h2>*/}
                            {/*</div>*/}
                            <div className="uk-card-body">
                                {course?.levels && course?.levels.length > 0 && <>
                                    <p>
                                        <strong>Niveles: </strong>
                                        <span>
                                            {formatConjunction(course?.levels)}
                                        </span>
                                    </p>
                                </>}

                                {course?.disciplines && course?.disciplines.length > 0 && <>
                                    <p>
                                        <strong>Diciplinas: </strong>
                                        <span>
                                            {formatConjunction(course?.disciplines)}
                                        </span>
                                    </p>
                                </>}

                                {course?.benefits && <>
                                    <p>
                                        <strong>Beneficios: </strong>
                                    </p>
                                    {
                                        course?.benefits && <ul className="uk-list uk-list-disc uk-list-primary">
                                            {course?.benefits?.split('\n').map(benefit => <li key={benefit}>{benefit}</li>)}
                                        </ul>
                                    }
                                </>}

                                {course?.requirements && course?.requirements.length > 0 && <>
                                    <p>
                                        <strong>Requisitos: </strong>
                                        <span>
                                            {formatConjunction(course?.requirements)}
                                        </span>
                                    </p>
                                </>}
                                {/*<p>*/}
                                {/*    <strong>Ubicaciones:</strong>*/}
                                {/*</p>*/}

                                {/*{course?.addresses.map(address => (*/}
                                {/*    <Fragment key={address.name}>*/}
                                {/*        <p className="uk-margin-remove-bottom">*/}
                                {/*            {address.name}*/}
                                {/*        </p>*/}
                                {/*        {*/}
                                {/*            address.latitude && address.longitude &&*/}
                                {/*            <a target="_blank" href={`https://www.google.com/maps/@${address.latitude},${address.longitude},15z`}>*/}
                                {/*                <Icon path={mdiOpenInNew} size={1} />*/}
                                {/*                Ver en Google Maps*/}
                                {/*            </a>*/}
                                {/*        }*/}
                                {/*    </Fragment>*/}
                                {/*))}*/}

                                <hr />

                                <div className="uk-grid-small" data-uk-grid="">
                                    {!state?.isFromManagePage && <div className="uk-width-auto uk-flex uk-flex-middle" data-uk-margin="">
                                        <strong>COMPARTIR:</strong>
                                        <a href={`https://wa.me/?text=Mira este curso en Lynx Academy: ${window.location.href}`} target="_blank" data-uk-tooltip="Enviar enlace por WhatsApp">
                                            <Icon path={mdiWhatsapp} size={1} />
                                        </a>
                                        <a onClick={handleCopyLink} data-uk-tooltip="Copiar enlace">
                                            <Icon path={mdiLink} size={1} />
                                        </a>
                                    </div>}
                                    <div className="uk-width-expand uk-text-right">
                                        <h3 className="uk-margin-remove">
                                            {formatCurrencyMXN(course?.price ?? 0)}
                                        </h3>
                                        <p className="uk-margin-remove">
                                            {course?.singlePayment ? 'pago único' : 'precio por clase'}
                                        </p>
                                    </div>
                                </div>

                                {!state?.isFromManagePage && <div className="uk-margin uk-text-center">
                                    <Link
                                        to={user != null ? `/clase/${id}/registro` : `/clase/${id}/identificacion`}
                                        state={{ course }}
                                        className="uk-button uk-button-large uk-button-primary">
                                        Iniciar con el Registro <Icon path={mdiArrowRightCircleOutline} size={1} />
                                    </Link>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="uk-margin-large">
                    <h3>
                        Descripción:
                    </h3>
                    <p>
                        <TextToHtml text={course?.description ?? ''} />
                    </p>
                </div>
            </Content>
        </>
    );
}