import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from './components/footer';
import Header from './components/header';
import PrivateRoutes from './components/privateRoutes';
import ScrollToTop from './components/scrollToTop';
import { ADMIN, CLASSCAPTURIST, INSTRUCTOR, PRODUCTCAPTURIST } from './constants';
import AboutUs from './pages/aboutUs';
//import PaymentInformation from './pages/account/paymentInformation';
import ShippingAddress from './pages/account/shippingAddress';
import UpdateData from './pages/account/updateData';
import UpdatePassword from './pages/account/updatePassword';
import CreateAddress from './pages/addresses/create';
import EditAddress from './pages/addresses/edit';
import Register from './pages/auth/register';
import Cart from './pages/carts';
import CartIdentification from './pages/carts/identification';
import Configuration from './pages/configuracion';
import Contact from './pages/contact';
import Course from './pages/courses';
import CourseCreate from './pages/courses/create';
import CourseDetails from './pages/courses/details';
import CourseEdit from './pages/courses/edit';
import CourseIdentification from './pages/courses/identification';
import CourseManage from './pages/courses/manage';
import CoursePayment from './pages/courses/payment';
import CourseRegister from './pages/courses/register';
import CourseRegisterConfirmation from './pages/courses/registerConfirmation';
import Dashboard from './pages/dashboard';
import Home from './pages/home';
import LogDetails from './pages/myLogs/details';
import LogIndex from './pages/myLogs/index';
import OrderPayment from './pages/orders/payment';
import OrderPurshaceConfirmation from './pages/orders/purchaseConfirmation';
import OrderShipping from './pages/orders/shipping';
import ParticipantDetails from './pages/participants/details';
import ParticipantEdit from './pages/participants/edit';
import PrivacyPolity from './pages/privacyPolicy';
import Products from './pages/products';
import ProductCreate from './pages/products/create';
import ProductDetails from './pages/products/details';
import ProductEdit from './pages/products/edit';
import ProductsManage from './pages/products/manage';
import Subscription from './pages/subscriptions';
import SubscriptionDetails from './pages/subscriptions/details';
import SubscriptionEdit from './pages/subscriptions/edit';
import SubscriptionManage from './pages/subscriptions/manage';
import SiteSubscriptionManage from './pages/subscriptions/manageSiteSubscriptions';
import SubscriptionRenewal from './pages/subscriptions/renewal';
import SubscriptionRenewalConfirmation from './pages/subscriptions/renewalConfirmation';
import TermsAndConditions from './pages/termsAndConditions';
import User from './pages/users';
import UserCreate from './pages/users/create';
import UserDetails from './pages/users/details';
import UserEdit from './pages/users/edit';
import { UserProvider } from './userContext';
import DeclinedPayment from './pages/courses/declinedPayment';

export default function App() {
    return (
        <>
            <BrowserRouter>
                <UserProvider>
                    <Header />
                    <ScrollToTop />
                    <main>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/acerca-de" element={<AboutUs />} />
                            <Route path="/contacto" element={<Contact />} />
                            <Route path="/terminos-y-condiciones" element={<TermsAndConditions />} />
                            <Route path="/politica-de-privacidad" element={<PrivacyPolity />} />

                            <Route path="/registro-de-usuarios" element={<Register />} />

                            <Route path="/academia" element={<Course />} />
                            <Route path="/clase/:id" element={<CourseDetails />} />
                            <Route path="/clase/:id/identificacion" element={<CourseIdentification />} />
                            <Route path="/clase/:id/registro" element={<CourseRegister />} />

                            <Route path="/suscripcion/:id/informacion-de-pago" element={<CoursePayment />} />
                            <Route path="/suscripcion/:id/confirmacion-de-registro" element={<CourseRegisterConfirmation />} />

                            <Route path="/productos" element={<Products />} />
                            <Route path="/producto/:id" element={<ProductDetails />} />

                            <Route path="/carrito" element={<Cart />} />
                            <Route path="/carrito/identificacion" element={<CartIdentification />} />

                            <Route path="/pedido/:id/informacion-de-envio" element={<OrderShipping />} />
                            <Route path="/pedido/:id/informacion-de-pago" element={<OrderPayment />} />
                            <Route path="/pedido/:id/confirmacion-de-compra" element={<OrderPurshaceConfirmation />} />

                            <Route path="/pago-no-valido/:subscriptionId" element={<DeclinedPayment />} />

                            <Route element={<PrivateRoutes />}>
                                <Route path="/dashboard" element={<Dashboard />} />

                                {/*<Route path="/cuenta/informacion-de-pago" element={<PaymentInformation />} />*/}
                                <Route path="/cuenta/informacion-de-envio" element={<ShippingAddress />} />
                                <Route path="/cuenta/actualizacion-de-contrasena" element={<UpdatePassword />} />
                                <Route path="/cuenta/actualizacion-de-datos" element={<UpdateData />} />

                                <Route path="/suscripciones" element={<Subscription />} />
                                <Route path="/suscripcion/:id" element={<SubscriptionDetails />} />
                                <Route path="/suscripcion/:id/editar" element={<SubscriptionEdit />} />
                                <Route path="/suscripcion/:id/renovar" element={<SubscriptionRenewal />} />
                                <Route path="/suscripcion/:id/confirmacion-de-renovacion" element={<SubscriptionRenewalConfirmation />} />

                                <Route path="/participante/:id" element={<ParticipantDetails />} />
                                <Route path="/participante/:id/editar" element={<ParticipantEdit />} />
                            </Route>

                            <Route element={<PrivateRoutes roles={[INSTRUCTOR]} />}>

                            </Route>

                            <Route element={<PrivateRoutes roles={[CLASSCAPTURIST]} />}>
                                <Route path="/clase/nueva" element={<CourseCreate />} />
                                <Route path="/clase/:id/editar" element={<CourseEdit />} />

                                <Route path="/administrar-cursos" element={<CourseManage />} />
                                <Route path="/administrar-clases" element={<SubscriptionManage />} />
                                <Route path="/administrar-suscripciones" element={<SiteSubscriptionManage />} />
                            </Route>

                            <Route element={<PrivateRoutes roles={[PRODUCTCAPTURIST]} />}>
                                <Route path="/administrar-productos" element={<ProductsManage />} />

                                <Route path="/producto/nuevo" element={<ProductCreate />} />
                                <Route path="/producto/:id/editar" element={<ProductEdit />} />
                            </Route>

                            <Route element={<PrivateRoutes roles={[ADMIN]} />}>
                                <Route path="/usuarios" element={<User />} />
                                <Route path="/usuarios/:id" element={<UserDetails />} />
                                <Route path="/usuarios/nuevo" element={<UserCreate />} />
                                <Route path="/usuarios/:id/editar" element={<UserEdit />} />

                                <Route path="/configuracion" element={<Configuration />} />
                                <Route path="/configuracion/ubicaciones/nueva" element={<CreateAddress />} />
                                <Route path="/configuracion/ubicaciones/:id/editar" element={<EditAddress />} />
                                <Route path="/logs" element={<LogIndex />} />
                                <Route path="/logs/:id" element={<LogDetails />} />
                            </Route>
                        </Routes>
                    </main>
                    <Footer />
                </UserProvider>
            </BrowserRouter>
        </>
    );
}