import React, { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from "react";
//import { iAddressBase, iAddressOption } from "../interfaces/address";
import { iCourseForm } from "../interfaces/course";
import { iSelectOption } from "../interfaces/selectOption";
//import AddressBaseForm from "./addressBaseForm";
import Cleave from "cleave.js/react";
import { MultiValue } from "react-select";
import CreatableSelect from 'react-select/creatable';
import FileUpload, { FileStates } from "./fileUpload";
import InputDynamicList from "./inputDynamicList";
import { iTomSelectOption } from "./tomSelect";

type CreateOption = 'levels' | 'disciplines' | 'requirements';

/** Interfaz con las propiedades para el formulario de un curso */
interface iCourseFormProps {
    /** Id del curso. Se manda en el caso de la edición */
    id?: string;

    /** Modelo que maneja los errores. Sus propiedades se manejan en PascalCase ya que dependen de los modelos en el back */
    errors: { [key: string]: string[] };

    /** Función para manejar el submit */
    onSubmit: (courseData: iCourseForm/*, addressData: iAddressBase*/) => void;

    /** Indica si se hizo submit */
    formSubmitted: boolean;
}

/** Datos iniciales para el curso */
const defaultCourse: iCourseForm = {
    title: '',
    description: '',
    levels: [],
    disciplines: [],
    benefits: '',
    requirements: [],
    addressesId: [],
    recurrentClass: false,
    days: [],
    schedules: '',
    daysAndSchedules: '',
    singlePayment: true,
    classFrom: null,
    classTo: null,
    price: null
}

/** Datos iniciales para la dirección */
//const defaultAddress: iAddressBase = {
//    latitude: null,
//    longitude: null,
//    street: '',
//    streetNumber: '',
//    neighborhood: '',
//    zipCode: '',
//    city: '',
//    state: ''
//}

/** Componente con el formulario de un curso */
export default function CourseForm(props: iCourseFormProps) {
    const [courseModel, setCourseModel] = useState<iCourseForm>(defaultCourse);
    //const [addressModel, setAddressModel] = useState<iAddressBase>(defaultAddress);

    //const [addresses, setAddresses] = useState<iAddressOption[]>([]);

    const [formData, setFormData] = useState<{
        levels: iTomSelectOption[],
        disciplines: iTomSelectOption[],
        requirements: iTomSelectOption[]
    }>({
        levels: [],
        disciplines: [],
        requirements: [],
    });

    /** 
     * estado del componente 
     * @description utiliza esta variable para determinar cual es el estado actual del componente
     * */
    const state = useRef<"" | "loading" | "loaded" | "saving" | "done">("");

    /** 
     * id del curso 
     * @description utiliza esta variable para determinar si el curso ha cambiado entre renderizados
     * */
    const id = useRef<string>("");

    /** 
     * url donde se despliega el componente 
     * @description utiliza esta variable para determinar si el componente a cambiado de página
     * */
    const href = useRef<string>();

    const { errors, onSubmit, formSubmitted } = props;

    const getCourseFormData = () => {
        if (state.current != "loading") return;

        fetch(`/api/ClassCapturist/GetCourseFormData/${id.current}`)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                const course = data as iCourseForm;
                setCourseModel(course || []);
            });
    }

    const getFormData = () => {
        const process = [
            fetch('/api/Level/GetLevels')
                .then(res => res.json())
                .then(data => data?.map((item: iSelectOption): iTomSelectOption => {
                    return {
                        text: item.key,
                        value: item.value
                    }
                })),
            fetch('/api/Discipline/GetDisciplines')
                .then(res => res.json())
                .then(data => data?.map((item: iSelectOption): iTomSelectOption => {
                    return {
                        text: item.key,
                        value: item.value
                    }
                })),
            fetch('/api/Requirement/GetRequirements')
                .then(res => res.json())
                .then(data => data?.map((item: iSelectOption): iTomSelectOption => {
                    return {
                        text: item.key,
                        value: item.value
                    }
                }))
        ];

        Promise.all(process).then(result => {
            setFormData(() => {
                state.current = "loaded";

                return {
                    levels: result[0],
                    disciplines: result[1],
                    requirements: result[2]
                };
            });
        });
    }

    useEffect(() => getFormData(), [courseModel]);

    useEffect(() => {
        if (href.current != location.href) {
            state.current = "";
            href.current = location.href;
        }
    }, [location.href]);

    useEffect(() => {
        const newId = props?.id || "";

        if (newId == id.current) {
            if (!newId) { // si no hay id, es un nuevo registro
                if (state.current == "") {
                    state.current = "loading";
                    getFormData();
                }
            }
        } else {
            id.current = newId;
            state.current = "loading";

            if (!newId) {
                getFormData();
            } else {
                getCourseFormData();
            }
        }
    }, []);

    const getButtonText = () => {
        if (id.current) {
            return formSubmitted ? 'Actualizando...' : 'Actualizar';
        } else {
            return formSubmitted ? 'Guardando y Publicando...' : 'Guardar y Publicar';
        }
    };

    /** Función que maneja el cambio de los inputs y textarea en el modelo */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value, type } = e.target;

        const newValue = type == 'number' && value == '' ? null : value;

        setCourseModel(prevCourseModel => ({
            ...prevCourseModel,
            [name]: newValue
        }));
    };

    /** Función que maneja el cambio de los inputs del componente InputCurrency */
    const handleInputCurrency = (name: string, value: string) => {
        if ((courseModel as any)[name] != value) {
            setCourseModel(prevCourseModel => ({
                ...prevCourseModel,
                [name]: Number(value) || null
            }));
        }
    };

    /** Maneja el cambio del componente InputDynamicList */
    const handleInputDynamicChange = useCallback((name: string, value: string) => {
        setCourseModel(prevCourseModel => ({
            ...prevCourseModel,
            [name]: value
        }));
    }, [])

    /** Función que maneja el cambio de los inputs tipo radio */
    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        const newValue = value === 'true';

        setCourseModel({
            ...courseModel,
            [name]: newValue,
        });
    };

    ///** Función que maneja el cambio del componente TomSelect */
    //const handleSelectChange = useCallback((name: string, values: string | string[]) => {
    //    setCourseModel(prevCourseModel => ({
    //        ...prevCourseModel,
    //        [name]: values,
    //    }));
    //}, []);

    const handleLevelsChange = (options: MultiValue<{ value: string; label?: string }>) => {
        setCourseModel(prevModel => ({
            ...prevModel,
            levels: options?.map(m => m.value)
        }));
    }

    const handleDisciplinesChange = (options: MultiValue<{ value: string; label?: string }>) => {
        setCourseModel(prevModel => ({
            ...prevModel,
            disciplines: options?.map(m => m.value)
        }));
    }

    const handleRequirementsChange = (options: MultiValue<{ value: string; label?: string }>) => {
        setCourseModel(prevModel => ({
            ...prevModel,
            requirements: options?.map(m => m.value)
        }));
    }

    const handleCreateOption = (inputValue: string, option: CreateOption) => {
        const newOption = { value: inputValue, text: inputValue };

        if (option === 'levels') {
            setFormData(prevData => ({
                ...prevData,
                levels: [...prevData.levels, newOption],
            }));

            setCourseModel(prevModel => ({
                ...prevModel,
                levels: [...prevModel.levels, inputValue],
            }));
        } else if (option === 'disciplines') {
            setFormData(prevData => ({
                ...prevData,
                disciplines: [...prevData.disciplines, newOption],
            }));

            setCourseModel(prevModel => ({
                ...prevModel,
                disciplines: [...prevModel.disciplines, inputValue],
            }));
        } else if (option === 'requirements') {
            setFormData(prevData => ({
                ...prevData,
                requirements: [...prevData.requirements, newOption],
            }));

            setCourseModel(prevModel => ({
                ...prevModel,
                requirements: [...prevModel.requirements, inputValue],
            }));
        }
    };

    /** Función que maneja el cambio de los inputs tipo checkbox */
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = e.target;

        setCourseModel((prevModel) => {
            const selectedValues = checked
                ? [...prevModel[name as keyof iCourseForm] as string[], value]
                : (prevModel[name as keyof iCourseForm] as string[]).filter(v => v !== value);

            return {
                ...prevModel,
                [name]: selectedValues,
            };
        });
    };

    /**
     * maneja el cambio de estado del componente de las imagenes
     * @description el estado indica si el componente esta trabajando (subiendo una imagen) o no
     * @param state estado del componente
     */
    const handleFileStateChange = useCallback((state: FileStates) => {
        if (state == FileStates.Working) {

        } else {

        }
    }, [])

    /**
     * maneja el cambio en las imagenes
     * @param files lista de archivos
     */
    const handleFileChange = useCallback((files: Array<string>) => {
        setCourseModel(prevCourseModel => ({
            ...prevCourseModel,
            media: files,
        }));
    }, [])

    /** Función que maneja los datos de la dirección */
    //const handleAddressChange = (updatedAddress: iAddressBase) => {
    //    setAddressModel(updatedAddress);
    //};

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        onSubmit(courseModel/*, addressModel*/);
    }

    if (props.id && (state.current == "" || state.current == "loading")) {
        return "Obteniendo información...";
    }

    return (
        <form onSubmit={handleSubmit} className="uk-margin uk-form-stacked">
            <div className="uk-child-width-1-1 uk-child-width-1-2@s" data-uk-grid>
                <fieldset className="uk-fieldset" disabled={formSubmitted}>

                    <legend className="uk-legend">
                        Información general
                    </legend>

                    <div className="uk-form-horizontal">
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="title">Título:</label>
                            <div className="uk-form-controls">
                                <input
                                    type="text"
                                    id="title"
                                    name="title"
                                    value={courseModel.title}
                                    className="uk-input"
                                    onChange={handleChange}
                                    required />

                                {errors?.Title && errors.Title.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="description">Descripción:</label>
                            <div className="uk-form-controls">
                                <textarea
                                    id="description"
                                    name="description"
                                    value={courseModel.description}
                                    className="uk-textarea uk-height-small"
                                    onChange={handleChange}
                                    required />

                                {errors?.Description && errors.Description.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="levels">Niveles:</label>
                            <div className="uk-form-controls">
                                {/*<TomSelect*/}
                                {/*    id="levels"*/}
                                {/*    name="levels"*/}
                                {/*    options={formData?.levels}*/}
                                {/*    items={courseModel.levels}*/}
                                {/*    onValueChange={(value) => handleSelectChange("levels", value)}*/}
                                {/*    create*/}
                                {/*    multiple*/}
                                {/*/>*/}

                                <CreatableSelect
                                    id="levels"
                                    name="levels"
                                    onChange={handleLevelsChange}
                                    onCreateOption={(value: string) => handleCreateOption(value, 'levels')} // Maneja la creación de nuevas opciones
                                    placeholder=""
                                    value={courseModel.levels.map(l => {
                                        return {
                                            value: l,
                                            label: formData?.levels.find(option => option.value === l)?.text || l, // Usa el texto creado si no está en formData
                                        };
                                    })}
                                    options={formData?.levels.map(l => ({
                                        value: l.value,
                                        label: l.text,
                                    }))}
                                    isMulti
                                    closeMenuOnSelect={false}
                                />

                                {errors?.Levels && errors.Levels.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="disciplines">Diciplinas:</label>
                            <div className="uk-form-controls">
                                {/*<TomSelect*/}
                                {/*    id="disciplines"*/}
                                {/*    name="disciplines"*/}
                                {/*    options={formData?.disciplines}*/}
                                {/*    items={courseModel.disciplines}*/}
                                {/*    onValueChange={(value) => handleSelectChange("disciplines", value)}*/}
                                {/*    create*/}
                                {/*    multiple*/}
                                {/*/>*/}

                                <CreatableSelect
                                    id="disciplines"
                                    name="disciplines"
                                    onChange={handleDisciplinesChange}
                                    onCreateOption={(value: string) => handleCreateOption(value, 'disciplines')} // Maneja la creación de nuevas opciones
                                    placeholder=""
                                    value={courseModel.disciplines.map(d => {
                                        return {
                                            value: d,
                                            label: formData?.disciplines.find(option => option.value === d)?.text || d, // Usa el texto creado si no está en formData
                                        };
                                    })}
                                    options={formData?.disciplines.map(d => ({
                                        value: d.value,
                                        label: d.text,
                                    }))}
                                    isMulti
                                    closeMenuOnSelect={false}
                                />

                                {errors?.Disciplines && errors.Disciplines.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="benefits">Beneficios:</label>
                            <div className="uk-form-controls">
                                <InputDynamicList
                                    id="benefits"
                                    name="benefits"
                                    value={courseModel.benefits}
                                    onItemsChange={handleInputDynamicChange} />

                                {errors?.Benefits && errors.Benefits.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="requirements">Requisitos:</label>
                            <div className="uk-form-controls">
                                {/*<TomSelect*/}
                                {/*    id="requirements"*/}
                                {/*    name="requirements"*/}
                                {/*    options={formData?.requirements}*/}
                                {/*    items={courseModel.requirements}*/}
                                {/*    onValueChange={(value) => handleSelectChange("requirements", value)}*/}
                                {/*    create*/}
                                {/*    multiple*/}
                                {/*/>*/}

                                <CreatableSelect
                                    id="requirements"
                                    name="requirements"
                                    onChange={handleRequirementsChange}
                                    onCreateOption={(value: string) => handleCreateOption(value, 'requirements')} // Maneja la creación de nuevas opciones
                                    placeholder=""
                                    value={courseModel.requirements.map(r => {
                                        return {
                                            value: r,
                                            label: formData?.requirements.find(option => option.value === r)?.text || r, // Usa el texto creado si no está en formData
                                        };
                                    })}
                                    options={formData?.requirements.map(r => ({
                                        value: r.value,
                                        label: r.text,
                                    }))}
                                    isMulti
                                    closeMenuOnSelect={false}
                                />

                                {errors?.Requirements && errors.Requirements.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                </fieldset>

                {/*<fieldset className="uk-fieldset" disabled={formSubmitted}>*/}

                {/*    <legend className="uk-legend">*/}
                {/*        Ubicación*/}
                {/*    </legend>*/}

                {/*    <div className="uk-form-horizontal">*/}
                {/*        <div className="uk-margin-small">*/}
                {/*            <ul className="uk-list">*/}
                {/*                {addresses.map(a => (*/}
                {/*                    <li key={a.id}>*/}
                {/*                        <label>*/}
                {/*                            <input*/}
                {/*                                type="checkbox"*/}
                {/*                                name="addressesId"*/}
                {/*                                value={a.id}*/}
                {/*                                className="uk-checkbox uk-margin-small-right"*/}
                {/*                                checked={courseModel.addressesId.includes(a.id)}*/}
                {/*                                onChange={handleCheckboxChange}*/}
                {/*                            />*/}
                {/*                            {a.name}*/}
                {/*                        </label>*/}
                {/*                    </li>*/}
                {/*                ))}*/}
                {/*                <li>*/}
                {/*                    <label>*/}
                {/*                        <input*/}
                {/*                            type="checkbox"*/}
                {/*                            name="addressesId"*/}
                {/*                            value="0"*/}
                {/*                            className="uk-checkbox uk-margin-small-right"*/}
                {/*                            checked={courseModel.addressesId.includes('0')}*/}
                {/*                            onChange={handleCheckboxChange}*/}
                {/*                        />*/}
                {/*                        Otro*/}
                {/*                    </label>*/}
                {/*                </li>*/}
                {/*            </ul>*/}

                {/*            {errors?.AddressesId && errors.AddressesId.map((error, index) => (*/}
                {/*                <div key={index} className="uk-text-small uk-text-danger">{error}</div>*/}
                {/*            ))}*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div hidden={!courseModel.addressesId.includes('0')}>*/}
                {/*        <div className="uk-form-horizontal">*/}
                {/*            <AddressBaseForm*/}
                {/*                model={addressModel}*/}
                {/*                errors={errors}*/}
                {/*                onAddressChange={handleAddressChange}*/}
                {/*                requiredInputs={courseModel.addressesId.includes('0')}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</fieldset>*/}

                {/*<fieldset hidden={!courseModel.addressesId.includes('0')} disabled={formSubmitted} className="uk-fieldset">*/}

                {/*    <legend className="uk-legend">*/}
                {/*        Días y horarios*/}
                {/*    </legend>*/}

                {/*    <div className="uk-form-horizontal">*/}
                {/*        <div className="uk-margin-small">*/}
                {/*            <div className="uk-form-controls">*/}
                {/*                <div className="uk-flex">*/}
                {/*                    <label className="uk-form-label">*/}
                {/*                        <input*/}
                {/*                            type="radio"*/}
                {/*                            name="recurrentClass"*/}
                {/*                            value="false"*/}
                {/*                            className="uk-radio uk-margin-small-right"*/}
                {/*                            checked={!courseModel.recurrentClass}*/}
                {/*                            onChange={handleRadioChange}*/}
                {/*                            required />*/}
                {/*                        Fijos*/}
                {/*                    </label>*/}
                {/*                    <label className="uk-form-label">*/}
                {/*                        <input*/}
                {/*                            type="radio"*/}
                {/*                            name="recurrentClass"*/}
                {/*                            value="true"*/}
                {/*                            className="uk-radio uk-margin-small-right"*/}
                {/*                            checked={courseModel.recurrentClass}*/}
                {/*                            onChange={handleRadioChange}*/}
                {/*                            required />*/}
                {/*                        Recurrentes*/}
                {/*                    </label>*/}

                {/*                    {errors?.RecurrentClass && errors.RecurrentClass.map((error, index) => (*/}
                {/*                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>*/}
                {/*                    ))}*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="uk-form-horizontal" hidden={courseModel.recurrentClass}>*/}
                {/*        <div className="uk-margin-small">*/}
                {/*            <label className="uk-form-label" htmlFor="daysAndSchedules">Días y Horarios:</label>*/}
                {/*            <div className="uk-form-controls">*/}
                {/*                <textarea*/}
                {/*                    id="daysAndSchedules"*/}
                {/*                    name="daysAndSchedules"*/}
                {/*                    value={courseModel.daysAndSchedules}*/}
                {/*                    className="uk-textarea"*/}
                {/*                    onChange={handleChange}*/}
                {/*                    required={courseModel.addressesId.includes('0') && !courseModel.recurrentClass} />*/}

                {/*                {errors?.DaysAndSchedules && errors.DaysAndSchedules.map((error, index) => (*/}
                {/*                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>*/}
                {/*                ))}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="uk-form-horizontal" hidden={!courseModel.recurrentClass}>*/}
                {/*        <div className="uk-margin-small">*/}
                {/*            <label className="uk-form-label">Días:</label>*/}
                {/*            <div className="uk-form-controls">*/}
                {/*                <ol className="uk-subnav" data-uk-margin>*/}
                {/*                    <li>*/}
                {/*                        <label>*/}
                {/*                            <input*/}
                {/*                                type="checkbox"*/}
                {/*                                name="days"*/}
                {/*                                value="7"*/}
                {/*                                className="uk-checkbox"*/}
                {/*                                checked={courseModel.days.includes('7')}*/}
                {/*                                onChange={handleCheckboxChange}*/}
                {/*                            />*/}
                {/*                            DOM*/}
                {/*                        </label>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <label>*/}
                {/*                            <input*/}
                {/*                                type="checkbox"*/}
                {/*                                name="days"*/}
                {/*                                value="1"*/}
                {/*                                className="uk-checkbox"*/}
                {/*                                checked={courseModel.days.includes('1')}*/}
                {/*                                onChange={handleCheckboxChange}*/}
                {/*                                required={courseModel.addressesId.includes('0') && courseModel.recurrentClass && courseModel.days.length <= 0}*/}
                {/*                            />*/}
                {/*                            LUN*/}
                {/*                        </label>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <label>*/}
                {/*                            <input*/}
                {/*                                type="checkbox"*/}
                {/*                                name="days"*/}
                {/*                                value="2"*/}
                {/*                                className="uk-checkbox"*/}
                {/*                                checked={courseModel.days.includes('2')}*/}
                {/*                                onChange={handleCheckboxChange}*/}
                {/*                            />*/}
                {/*                            MAR*/}
                {/*                        </label>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <label>*/}
                {/*                            <input*/}
                {/*                                type="checkbox"*/}
                {/*                                name="days"*/}
                {/*                                value="3"*/}
                {/*                                className="uk-checkbox"*/}
                {/*                                checked={courseModel.days.includes('3')}*/}
                {/*                                onChange={handleCheckboxChange}*/}
                {/*                            />*/}
                {/*                            MIE*/}
                {/*                        </label>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <label>*/}
                {/*                            <input*/}
                {/*                                type="checkbox"*/}
                {/*                                name="days"*/}
                {/*                                value="4"*/}
                {/*                                className="uk-checkbox"*/}
                {/*                                checked={courseModel.days.includes('4')}*/}
                {/*                                onChange={handleCheckboxChange}*/}
                {/*                            />*/}
                {/*                            JUE*/}
                {/*                        </label>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <label>*/}
                {/*                            <input*/}
                {/*                                type="checkbox"*/}
                {/*                                name="days"*/}
                {/*                                value="5"*/}
                {/*                                className="uk-checkbox"*/}
                {/*                                checked={courseModel.days.includes('5')}*/}
                {/*                                onChange={handleCheckboxChange}*/}
                {/*                            />*/}
                {/*                            VIE*/}
                {/*                        </label>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <label>*/}
                {/*                            <input*/}
                {/*                                type="checkbox"*/}
                {/*                                name="days"*/}
                {/*                                value="6"*/}
                {/*                                className="uk-checkbox"*/}
                {/*                                checked={courseModel.days.includes('6')}*/}
                {/*                                onChange={handleCheckboxChange}*/}
                {/*                            />*/}
                {/*                            SAB*/}
                {/*                        </label>*/}
                {/*                    </li>*/}
                {/*                </ol>*/}

                {/*                {errors?.Days && errors.Days.map((error, index) => (*/}
                {/*                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>*/}
                {/*                ))}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="uk-form-horizontal" hidden={!courseModel.recurrentClass}>*/}
                {/*        <div className="uk-margin-small">*/}
                {/*            <label className="uk-form-label" htmlFor="schedules">Horarios:</label>*/}
                {/*            <div className="uk-form-controls">*/}
                {/*                <textarea*/}
                {/*                    id="schedules"*/}
                {/*                    name="schedules"*/}
                {/*                    value={courseModel.schedules}*/}
                {/*                    className="uk-textarea"*/}
                {/*                    onChange={handleChange}*/}
                {/*                    required={courseModel.addressesId.includes('0') && courseModel.recurrentClass} />*/}

                {/*                {errors?.Schedules && errors.Schedules.map((error, index) => (*/}
                {/*                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>*/}
                {/*                ))}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</fieldset>*/}

                <fieldset className="uk-fieldset" disabled={formSubmitted}>
                    <legend className="uk-legend">
                        Imagenes
                    </legend>

                    <FileUpload
                        value={courseModel?.media}
                        onChange={handleFileChange}
                        onChangeState={handleFileStateChange}
                    />

                    <legend className="uk-legend">
                        Formas de pago
                    </legend>

                    <div className="uk-form-horizontal">
                        <div className="uk-margin-small">
                            <label className="uk-form-label">Forma de Pago:</label>
                            <div className="uk-form-controls">
                                <div className="uk-flex">
                                    <label className="uk-form-label">
                                        <input
                                            type="radio"
                                            name="singlePayment"
                                            value="true"
                                            className="uk-radio uk-margin-small-right"
                                            checked={courseModel.singlePayment}
                                            onChange={handleRadioChange}
                                            required />
                                        Pago único
                                    </label>
                                    <label className="uk-form-label">
                                        <input
                                            type="radio"
                                            name="singlePayment"
                                            value="false"
                                            className="uk-radio uk-margin-small-right"
                                            checked={!courseModel.singlePayment}
                                            onChange={handleRadioChange}
                                            required />
                                        Pago por clase
                                    </label>

                                    {errors?.SinglePayment && errors.SinglePayment.map((error, index) => (
                                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-form-horizontal" hidden={courseModel.singlePayment}>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="classFrom">Clases:</label>
                            <div className="uk-form-controls">
                                <div className="uk-grid-small" data-uk-grid>
                                    <div className="uk-width-1-1 uk-width-1-2@s">
                                        <input
                                            type="number"
                                            id="classFrom"
                                            name="classFrom"
                                            value={courseModel.classFrom ?? ''}
                                            className="uk-input"
                                            onChange={handleChange}
                                            required={!courseModel.singlePayment}
                                            placeholder="Mínimo"
                                        />
                                    </div>
                                    <div className="uk-width-1-1 uk-width-1-2@s">
                                        <input
                                            type="number"
                                            id="classTo"
                                            name="classTo"
                                            value={courseModel.classTo ?? ''}
                                            className="uk-input"
                                            onChange={handleChange}
                                            required={!courseModel.singlePayment}
                                            placeholder="Máximo"
                                        />
                                    </div>

                                    {errors?.ClassFrom && errors.ClassFrom.map((error, index) => (
                                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                    ))}

                                    {errors?.ClassTo && errors.ClassTo.map((error, index) => (
                                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-form-horizontal">
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="price">{courseModel.singlePayment ? 'Precio:' : 'Precio por Clase:'}</label>
                            <div className="uk-form-controls">
                                <Cleave
                                    id="price"
                                    name="price"
                                    value={courseModel.price || ''}
                                    required
                                    autoComplete="transaction-amount"
                                    className="uk-input"
                                    options={{
                                        numeral: true,
                                        numeralThousandsGroupStyle: "thousand",
                                        numeralPositiveOnly: true
                                    }}
                                    onChange={(e: ChangeEvent) => handleInputCurrency("price", (e.target as any).rawValue)}
                                />

                                {errors?.Price && errors.Price.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>

            <button type="submit" className="uk-button uk-button-primary uk-margin-medium-top" disabled={formSubmitted}>
                {getButtonText()}
            </button>
        </form >
    );
}