import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { iPagination } from "../interfaces/pagination";
import { iProductFilters, iProductIndex } from "../interfaces/product";
import { getPaginationRangeText } from "../utils";
import Pagination from "./pagination";
import ProductCardInfo from "./productCardInfo";

/**
 * Muestra los datos necesarios para el index de los productos.
 * 
 * @param isFromManagePage indica si el componente se esta llamando desde la administración de productos.
 * @returns
 */
export default function ProductIndex({ isFromManagePage = false }: { isFromManagePage?: boolean }) {
    const [data, setData] = useState<iProductIndex | null>(null);
    const [pagination, setPagination] = useState<iPagination | null>(null);
    const [filters, setFilters] = useState<iProductFilters | null>(null);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const params: any = Object.fromEntries(searchParams.entries());

        setFilters({
            name: params.name || '',
        });

        setPagination({
            itemsPerPage: Number(params.itemsPerPage) || undefined,
            currentPage: Number(params.currentPage) || undefined,
            orderBy: params.orderBy,
            orderMode: params.orderModes
        });

        fetchData()
    }, [searchParams]);

    const fetchData = () => {
        const queryString = searchParams.toString();

        fetch(`/api/Product/Index?${queryString}`)
            .then(res => res.json())
            .then(data => {
                setData(data as iProductIndex);
            });
    }

    const handlePageChange = useCallback((page: number) => {
        const updatedPagination = { ...pagination, currentPage: page };

        // NOTE: No se realiza la actualización del modelo "Pagination" aquí ya que al cambiar "SearchParams" se realizá dicha actualización.
        //setPagination(updatedPagination);

        setSearchParams(prev => {
            const params = new URLSearchParams(prev);

            Object.entries(updatedPagination).forEach(([key, value]) => {
                if (value === undefined || value === "") {
                    params.delete(key);
                } else {
                    params.set(key, String(value));
                }
            });

            return params;
        });
    }, [pagination]);

    const handleFilterChange = (newFilters: iProductFilters) => {
        // NOTE: No se realiza la actualización del modelo "Filters" aquí ya que al cambiar "SearchParams" se realizá dicha actualización.
        //setFilters(newFilters);

        setSearchParams(prev => {
            const params = new URLSearchParams(prev);

            Object.entries(newFilters).forEach(([key, value]) => {
                if (value === undefined || value === "") {
                    params.delete(key);
                } else {
                    params.set(key, String(value));
                }
            });

            params.set("page", "1");

            return params;
        });
    }

    return (
        <>
            <div className="uk-margin">
                <div className="uk-flex uk-flex-between uk-flex-middle">
                    <div>
                        {getPaginationRangeText(data?.pagination?.currentPage, data?.pagination?.itemsPerPage, data?.totalItems)}
                    </div>
                </div>
            </div>

            <div className="uk-margin">
                <div className="uk-child-width-1-2@s" data-uk-grid>
                    {data && data.list && data.list?.length > 0 ? (
                        data.list.map(product => (
                            <div key={product.id}>
                                <ProductCardInfo product={product} isFromManagePage={isFromManagePage} />
                            </div>
                        ))
                    ) : (
                        <p>No hay productos disponibles.</p>
                    )}
                </div>
            </div>

            {(data?.totalPages ?? 0) > 1 && <div className="uk-margin">
                <Pagination
                    currentPage={pagination?.currentPage ?? 1}
                    totalPages={data?.totalPages ?? 1}
                    onPageChange={handlePageChange}
                />
            </div>}
        </>
    );
}