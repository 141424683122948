import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Content from "../../components/content";
import Heading from "../../components/heading";
import Pagination from "../../components/pagination";
import SubscriptionCardInfo from "../../components/subscriptionCardInfo";
import UsePageTitle from "../../components/usePageTitle";
import { iPagination } from "../../interfaces/pagination";
import { iSubscriptionIndex } from "../../interfaces/subscription";
import { getPaginationRangeText } from "../../utils";

/** Página para administrar las clases (Suscripciones a los cursos) */
export default function SubscriptionManage() {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState<iSubscriptionIndex | null>(null);
    const [pagination, setPagination] = useState<iPagination | null>(null);
    const [query, setQuery] = useState<string>('');

    useEffect(() => {
        const paginationParams = new URLSearchParams(Object.entries(pagination ?? {}))

        const paginationQueryString = paginationParams.toString();

        let params = '';

        if (paginationQueryString) {
            if (params) {
                params += `&${paginationQueryString}`;
            } else {
                params += paginationQueryString;
            }
        }

        if (location.search != params) {
            setQuery(params);
            navigate({ search: params });
        }
    }, [pagination]);

    useEffect(() => {
        fetch(`/api/ClassCapturist/Subscription/Index?${query}`)
            .then(res => res.json())
            .then(data => {
                setData(data as iSubscriptionIndex);
            });
    }, [query]);

    useEffect(() => {
        const search = location.search.slice(1);

        if (search != query) {
            setQuery(search);
        }
    }, [location.search])

    const handlePageChange = useCallback((page: number) => {
        setPagination({
            ...pagination,
            currentPage: page
        });
    }, []);

    UsePageTitle('Administración de clases');

    return (
        <Content>
            <Heading text="Administración de clases" />

            <section className="uk-section uk-section-xsmall">
                <div className="uk-flex uk-flex-between uk-flex-middle">
                    <div>
                        {getPaginationRangeText(data?.pagination?.currentPage, data?.pagination?.itemsPerPage, data?.totalItems)}
                    </div>
                </div>
            </section>

            {data && data.list && data.list?.length > 0 && <>
                <section className="uk-section uk-section-xsmall">
                    <div className="uk-card uk-card-small uk-card-default uk-flex uk-flex-column">
                        <ul className="uk-list uk-list-divider uk-margin-remove">
                            {data.list.map(subscription => (
                                <li key={subscription.id} className="uk-padding-small uk-margin-remove">
                                    <SubscriptionCardInfo subscription={subscription} isFromManagePage={true} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </section>
            </>}

            {(data?.totalPages ?? 0) > 1 && <div className="uk-margin">
                <Pagination
                    currentPage={pagination?.currentPage ?? 1}
                    totalPages={data?.totalPages ?? 1}
                    onPageChange={handlePageChange}
                />
            </div>}
        </Content>
    );
}