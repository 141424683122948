import { mdiCheckCircle } from "@mdi/js";
import { Icon } from "@mdi/react";
import "cleave.js/dist/addons/cleave-phone.mx.js";
import Cleave from "cleave.js/react";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import UIkit from "uikit";
import Content from "../../components/content";
import Heading from "../../components/heading";
import SelectedCourseInfo from "../../components/selectedCourseInfo";
import UsePageTitle from "../../components/usePageTitle";
import { iCourseDetails } from "../../interfaces/course";
import { iPaymentForm } from "../../interfaces/payment";
import { iStatus } from "../../interfaces/status";
import { iSubscriptionDetails } from "../../interfaces/subscription";
import { formatCurrencyMXN } from "../../utils";

/** Página para realizar el pago de un curso */
export default function CoursePayment() {
    const { id } = useParams<{ id: string }>();

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const { state } = useLocation() as {
        state: { course: iCourseDetails, newUser: boolean } | null;
    };

    const [course, setCourse] = useState<iCourseDetails | null>(state?.course || null);
    const [subscription, setSubscription] = useState<iSubscriptionDetails | null>(null);
    const [payment, setPayment] = useState<iPaymentForm>({
        BNRG_ID_AFILIACION: '8379501',
        BNRG_ID_MEDIO: 'J3LPFKQG',
        BNRG_NUMERO_TARJETA: '',
        BNRG_FECHA_EXP: '',
        BNRG_CODIGO_SEGURIDAD: '',
        BNRG_MONTO_TRANS: '',
        BNRG_FOLIO: '0000000',
        BNRG_MODO_TRANS: 'AUT',
        BNRG_HORA_LOCAL: '',
        BNRG_FECHA_LOCAL: '',
        BNRG_URL_RESPUESTA: window.location.origin + window.location.pathname,
        BNRG_MODO_ENTRADA: 'MANUAL',
        BNRG_CMD_TRANS: 'VENTA',
    });
    const [activeSiteSubscription, setActiveSiteSubscription] = useState<boolean>(false);
    const [pendingSiteSubscription, setPendingSiteSubscription] = useState<boolean>(false);
    const [siteSubscriptionPrice, setSiteSubscriptionPrice] = useState<number>(0);
    const [makePaymentInClass, setMakePaymentInClass] = useState<boolean>(false);
    //const [savePaymentInformation, setSavePaymentInformation] = useState<boolean>(false);
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    //const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

    useEffect(() => {
        Promise.all([
            fetch('/api/Me/SiteSubscription/IsActive').then(res => res.json()),
            fetch('/api/Me/SiteSubscription/IsPending').then(res => res.json()),
            fetch('/api/Me/SiteSubscription/Price').then(res => res.json())
        ])
            .then(([IsActive, IsPending, Price]) => {
                setActiveSiteSubscription(IsActive as boolean);
                setPendingSiteSubscription(IsPending as boolean);
                setSiteSubscriptionPrice(Price as number);
            });
    }, []);

    useEffect(() => {
        setPayment({ ...payment, BNRG_FOLIO: `${subscription?.id}` });
    }, [subscription]);

    useEffect(() => {
        fetch(`/api/Me/Subscription/Details/${id}`)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                setSubscription(data as iSubscriptionDetails);
            });

        //fetch('/api/Me/SiteSubscription/IsActive')
        //    .then(res => res.json())
        //    .then(data => {
        //        setActiveSiteSubscription(data as boolean);
        //    });

        //fetch('/api/Me/SiteSubscription/IsPending')
        //    .then(res => res.json())
        //    .then(data => {
        //        setPendingSiteSubscription(data as boolean);
        //    });

        //fetch('/api/Me/SiteSubscription/Price')
        //    .then(res => res.json())
        //    .then(data => {
        //        setSiteSubscriptionPrice(data as number);
        //    });
    }, [id]);

    useEffect(() => {
        if (subscription) {
            /** Realiza el fetch solo si no se mando la información del curso desde la página anterior */
            if (!state?.course) {
                fetch(`/api/Course/Details/${subscription.courseId}`)
                    .then(res => {
                        if (!res.ok) {
                            window.location.href = `${window.location.origin}/Error`;
                        }

                        return res.json()
                    })
                    .then(data => {
                        const course = data as iCourseDetails;

                        // Cambia el precio del curso para evitar que se muestre un precio distinto por el que se pago en la suscripción en caso de que editen el curso.
                        course.price = subscription.acquiredClasses
                            ? subscription.price / subscription.acquiredClasses
                            : subscription.price;

                        setCourse(course);
                    });
            } else {
                // Cambia el precio del curso para evitar que se muestre un precio distinto por el que se pago en la suscripción en caso de que editen el curso.
                state.course.price = subscription.acquiredClasses
                    ? subscription.price / subscription.acquiredClasses
                    : subscription.price;

                setCourse(state.course);
            }
        }
    }, [state?.course, subscription]);

    useEffect(() => {
        const queryObject: Record<string, string> = Object.fromEntries(
            searchParams.entries()
        );

        if (Object.keys(queryObject).length > 0) {
            const requestOptions: RequestInit = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(queryObject)
            };

            sendData(requestOptions, false);
        }
    }, [searchParams]);

    useEffect(() => {
        setPayment((prevModel) => ({
            ...prevModel,
            BNRG_MONTO_TRANS: `${(subscription?.price ?? 0) + (activeSiteSubscription || pendingSiteSubscription ? 0 : siteSubscriptionPrice)}`,
        }));
    }, [subscription, activeSiteSubscription, pendingSiteSubscription, siteSubscriptionPrice])

    useEffect(() => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); // Enero es 0
        const yyyy = today.getFullYear();
        const hora = String(today.getHours()).padStart(2, "0");
        const min = String(today.getMinutes()).padStart(2, "0");
        const seg = String(today.getSeconds()).padStart(2, "0");

        setPayment((prevModel) => ({
            ...prevModel,
            BNRG_HORA_LOCAL: `${hora}${min}${seg}`,
            BNRG_FECHA_LOCAL: `${dd}${mm}${yyyy}`,
        }));
    }, []);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        setFormSubmitted(true);

        if (makePaymentInClass) {
            const requestOptions: RequestInit = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: null
            };

            sendData(requestOptions, makePaymentInClass);
        } else {
            const form = document.createElement("form");
            form.method = "POST";
            form.action = "https://testcolecto.banregio.com/adq/";

            Object.entries(payment).forEach(([key, value]) => {
                const input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = value;
                form.appendChild(input);
            });

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        }

        setFormSubmitted(false);

        //const requestOptions: RequestInit = {
        //    method: 'POST',
        //    headers: {
        //        'Content-Type': 'application/json',
        //    },
        //    body: makePaymentInClass ? null : JSON.stringify(payment)
        //};

        //setFormSubmitted(true);

        //fetch(`/api/Me/Subscription/${id}/Pay?makePaymentInClass=${makePaymentInClass}`, requestOptions)
        //    .then(res => res.json())
        //    .then(data => {
        //        const status = data as iStatus

        //        setErrors({});

        //        if (status.success) {
        //            // NOTE: Se pasan datos a la siguiente página con state para evitar consultas a la DB.
        //            navigate(`/suscripcion/${id}/confirmacion-de-registro`, { state: { subscription, course } })
        //        };

        //        status.messages?.forEach(mesagge => {
        //            UIkit.notification({
        //                message: `${mesagge}`,
        //                status: status.success ? 'success' : 'danger',
        //                pos: 'top-center'
        //            });
        //        });

        //        if (status.errors) setErrors(data.errors);
        //    })
        //    .finally(() => {
        //        setFormSubmitted(false);
        //    });
    }

    const sendData = (requestOptions: RequestInit, makePaymentInClass: boolean) => {
        fetch(`/api/Me/Subscription/${id}/Pay?makePaymentInClass=${makePaymentInClass}`, requestOptions)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                const status = data as iStatus

                if (status.success) {
                    // NOTE: Se pasan datos a la siguiente página con state para evitar consultas a la DB.
                    navigate(`/suscripcion/${id}/confirmacion-de-registro`, { state: { subscription, course } })
                } else {
                    navigate(`/pago-no-valido/${id}?code=${status.id}&message=${status.messages[0]}`)
                }

                //status.messages?.forEach(mesagge => {
                //    UIkit.notification({
                //        message: `${mesagge}`,
                //        status: status.success ? 'success' : 'danger',
                //        pos: 'top-center'
                //    });
                //});
            });
    }

    const handlePaymentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        setPayment(prevPayment => ({
            ...prevPayment,
            [name]: value
        }));
    };

    ///** Función que maneja el cambio del componente InputPhone */
    //const handleInputPhone = (name: string, value: string) => {
    //    setPayment(prevPayment => ({
    //        ...prevPayment,
    //        [name]: value
    //    }));
    //};

    //const breadcrumbItems: iBreadcrumbItem[] = [
    //    { path: '/', text: 'Inicio' },
    //    { path: '/academia', text: 'Academia' },
    //    { path: `/clase/${id}`, text: `${course?.title}` },
    //    { path: `/suscripcion/${id}/informacion-de-pago`, text: 'Información de pago' }
    //];

    UsePageTitle('Información de Pago');

    return (
        <Content>
            <Heading text="Información de Pago" />
            <div className="uk-child-width-1-1 uk-child-width-1-3@m" data-uk-grid>
                <div className="uk-width-2-3@m">
                    {state?.newUser && <div className="uk-alert-success" data-uk-alert="">
                        <div className="uk-grid-small" data-uk-grid>
                            <div className="uk-width-auto">
                                <span>
                                    <Icon path={mdiCheckCircle} size={1} />
                                </span>
                            </div>
                            <div className="uk-width-expand">
                                <span>
                                    Hemos enviado la información de tu usuario y contraseña al correo electrónico que proporcionaste. Por favor, revisa tu bandeja de entrada y, si no lo encuentras, verifica también la carpeta de correo no deseado o spam. Si tienes algún problema, no dudes en contactarnos.
                                </span>
                            </div>
                        </div>
                    </div>}

                    <div className="uk-margin-large">
                        <h3>
                            Desglose de Precios
                        </h3>

                        {/*<div className="uk-tile uk-tile-muted uk-padding-small">*/}
                        {/*<div data-uk-grid="">*/}
                        {/*    <div className="uk-width-auto">*/}
                        {/*        {subscription?.acquiredClasses*/}
                        {/*            ? <div>Total por {subscription.acquiredClasses == 1 ? "1 Clase" : subscription.acquiredClasses + " Clases"}:</div>*/}
                        {/*            : <div>Precio del Curso:</div>}*/}

                        {/*        {!activeSiteSubscription && !pendingSiteSubscription && <div>Membresia Anual:</div>}*/}

                        {/*        <div>Total a Pagar:</div>*/}
                        {/*    </div>*/}
                        {/*    <div className="uk-width-expand">*/}
                        {/*        <div>*/}
                        {/*            <strong>{formatCurrencyMXN(subscription?.price ?? 0)}</strong>*/}
                        {/*        </div>*/}
                        {/*        {!activeSiteSubscription && !pendingSiteSubscription && <div>*/}
                        {/*            <strong>{formatCurrencyMXN(siteSubscriptionPrice)}</strong>*/}
                        {/*        </div>}*/}
                        {/*        <div>*/}
                        {/*            <strong>{formatCurrencyMXN((subscription?.price ?? 0) + (activeSiteSubscription || pendingSiteSubscription ? 0 : siteSubscriptionPrice))}</strong>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="uk-child-width-1-3@s" data-uk-grid="">
                            <div>
                                <div className="uk-tile uk-tile-xsmall uk-tile-muted uk-text-center">
                                    {subscription?.acquiredClasses
                                        ? <div>Total por {subscription.acquiredClasses == 1 ? "1 Clase" : subscription.acquiredClasses + " Clases"}:</div>
                                        : <div>Precio del Curso:</div>}
                                    <strong>{formatCurrencyMXN(subscription?.price ?? 0)}</strong>
                                </div>
                            </div>
                            {!activeSiteSubscription && !pendingSiteSubscription && <div>
                                <div className="uk-tile uk-tile-xsmall uk-tile-muted uk-text-center">
                                    <div>Membresia Anual:</div>
                                    <strong>{formatCurrencyMXN(siteSubscriptionPrice)}</strong>
                                </div>
                            </div>}
                            <div>
                                <div className="uk-tile uk-tile-xsmall uk-tile-muted uk-text-center">
                                    <div>
                                        <strong>
                                            Total a Pagar:
                                        </strong>
                                    </div>
                                    <strong>{formatCurrencyMXN((subscription?.price ?? 0) + (activeSiteSubscription || pendingSiteSubscription ? 0 : siteSubscriptionPrice))}</strong>
                                </div>
                            </div>
                        </div>
                        {/*</div>*/}
                    </div>
                    <div className="uk-margin-large">
                        <form onSubmit={handleSubmit} className="uk-form-stacked">
                            <fieldset className="uk-fieldset" disabled={formSubmitted}>
                                <div className="uk-margin">
                                    <h3 className="uk-margin-remove">
                                        <label>
                                            <input
                                                type="radio"
                                                name="pay-mode"
                                                className="uk-radio"
                                                checked={!makePaymentInClass}
                                                onChange={(e) => setMakePaymentInClass(e.target.checked == false)}
                                            />
                                            &nbsp;
                                            Pagar Ahora
                                        </label>
                                    </h3>
                                    <p className="uk-margin-remove">
                                        Realiza el pago en línea y empieza con tus clases inmediatamente
                                    </p>
                                </div>

                                {!makePaymentInClass && <>
                                    <div className="uk-grid-small uk-child-width-1-2" data-uk-grid="">
                                        <div className="uk-width-1-1">
                                            <label className="uk-form-label" htmlFor="BNRG_NUMERO_TARJETA">Número de tarjeta:</label>
                                            <div className="uk-form-controls">
                                                <Cleave
                                                    id="BNRG_NUMERO_TARJETA"
                                                    name="BNRG_NUMERO_TARJETA"
                                                    value={payment.BNRG_NUMERO_TARJETA}
                                                    required={!makePaymentInClass}
                                                    autoComplete="cc-number"
                                                    className="uk-input"
                                                    options={{
                                                        creditCard: true
                                                    }}
                                                    onChange={(e: ChangeEvent) => setPayment(prevModel => ({ ...prevModel, BNRG_NUMERO_TARJETA: (e.target as any).rawValue }))}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label className="uk-form-label" htmlFor="BNRG_FECHA_EXP">Fecha de Expiración:</label>
                                            <div className="uk-form-controls">
                                                <Cleave
                                                    id="BNRG_FECHA_EXP"
                                                    name="BNRG_FECHA_EXP"
                                                    value={payment.BNRG_FECHA_EXP}
                                                    required={!makePaymentInClass}
                                                    autoComplete="cc-exp"
                                                    placeholder="MM/AA"
                                                    className="uk-input"
                                                    options={{
                                                        date: true,
                                                        datePattern: ['m', 'y']
                                                    }}
                                                    onChange={(e: ChangeEvent) => setPayment(prevModel => ({ ...prevModel, BNRG_FECHA_EXP: (e.target as any).rawValue }))}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label className="uk-form-label" htmlFor="BNRG_CODIGO_SEGURIDAD">Código de seguridad (CVV):</label>
                                            <div className="uk-form-controls">
                                                <input
                                                    type="password"
                                                    maxLength={4}
                                                    id="BNRG_CODIGO_SEGURIDAD"
                                                    name="BNRG_CODIGO_SEGURIDAD"
                                                    className="uk-input"
                                                    value={payment.BNRG_CODIGO_SEGURIDAD}
                                                    onChange={handlePaymentChange}
                                                    required={!makePaymentInClass}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <input type="hidden" name="BNRG_ID_AFILIACION" value={payment.BNRG_ID_AFILIACION} required={!makePaymentInClass} />
                                    <input type="hidden" name="BNRG_ID_MEDIO" value={payment.BNRG_ID_MEDIO} required={!makePaymentInClass} />
                                    <input type="hidden" name="BNRG_FOLIO" value={payment.BNRG_FOLIO} required={!makePaymentInClass} />
                                    <input type="hidden" name="BNRG_MODO_TRANS" value={payment.BNRG_MODO_TRANS} required={!makePaymentInClass} />
                                    <input type="hidden" name="BNRG_HORA_LOCAL" value={payment.BNRG_HORA_LOCAL} />
                                    <input type="hidden" name="BNRG_FECHA_LOCAL" value={payment.BNRG_FECHA_LOCAL} />
                                    <input type="hidden" name="BNRG_URL_RESPUESTA" value={payment.BNRG_URL_RESPUESTA} />
                                    <input type="hidden" name="BNRG_MODO_ENTRADA" value={payment.BNRG_MODO_ENTRADA} />
                                    <input type="hidden" name="BNRG_CMD_TRANS" value={payment.BNRG_CMD_TRANS} />
                                </>}

                                <div className="uk-margin">
                                    <h3 className="uk-margin-remove">
                                        <label>
                                            <input
                                                type="radio"
                                                name="pay-mode"
                                                className="uk-radio"
                                                checked={makePaymentInClass}
                                                onChange={(e) => setMakePaymentInClass(e.target.checked == true)}
                                            />
                                            &nbsp;
                                            Pagar Después
                                        </label>
                                    </h3>
                                    <p className="uk-margin-remove">
                                        Realiza el pago en cualquiera de nuestras sucursales
                                    </p>
                                </div>

                                <button type="submit" className="uk-button uk-button-primary uk-margin-medium-top" disabled={formSubmitted}>
                                    Finalizar el registro
                                </button>
                            </fieldset>
                        </form>
                    </div>
                </div>
                <div>
                    {course != null && <SelectedCourseInfo course={course} btnSelectAnotherCourse={true} />}
                </div>
            </div>
        </Content>
    );
}