import { mdiLink, mdiWhatsapp } from "@mdi/js";
import { Icon } from "@mdi/react";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import UIkit from "uikit";
import Breadcrumb from "../../components/breadcrumb";
import Content from "../../components/content";
import Heading from "../../components/heading";
import TextToHtml from "../../components/textToHtml";
import UsePageTitle from "../../components/usePageTitle";
import { PRODUCTCAPTURIST } from "../../constants";
import { iBreadcrumbItem } from "../../interfaces/breadcrumb";
import { iProductDetails } from "../../interfaces/product";
import { useUser } from "../../userContext";
import { formatConjunction, formatCurrencyMXN } from "../../utils";

/**  Página que muestra los detalles de un producto */
export default function ProductDetails() {
    const { user } = useUser();
    const [product, setProduct] = useState<iProductDetails | null>(null);
    const { id } = useParams<{ id: string }>();

    const { state } = useLocation() as {
        state: { isFromManagePage: boolean } | null;
    };

    useEffect(() => {
        fetch(`/api/Product/Details/${id}`)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                setProduct(data as iProductDetails);
            });
    }, [id]);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(window.location.href);

        UIkit.notification({
            message: 'Enlace copiado',
            status: 'success',
            pos: 'top-center'
        });
    }

    const breadcrumbItems: iBreadcrumbItem[] = [
        { path: '/', text: 'Inicio' },
        { path: '/productos', text: 'Productos' },
        //{ path: `/productos?category=${product?.category}`, text: `${product?.category}` }, // TODO: Descomentar al integrar los filtros.
        { path: `/producto/${product?.id}`, text: `${product?.name}` }
    ];

    UsePageTitle('Detalles del Producto');

    return (
        <Content>
            {state?.isFromManagePage && user?.roles.some(role => role == PRODUCTCAPTURIST)
                ? <Heading text="Detalles del Producto" backOption={state.isFromManagePage ? "/administrar-productos" : "/productos"} editOption={`/producto/${id}/editar`} deleteOption={`/api/Product/Delete/${id}`} />
                : <Heading text="Detalles del Producto" />}

            {!state?.isFromManagePage && <div>
                <Breadcrumb items={breadcrumbItems} />
            </div>}

            <div>
                <div className="uk-child-width-1-1 uk-child-width-1-2@s" data-uk-grid>
                    <div>
                        {
                            product?.media && product?.media.length > 0 ? (
                                <div className="uk-position-relative uk-light" data-uk-slideshow="ratio: 1:1">
                                    <div className="uk-slideshow-items" data-uk-lightbox>
                                        {
                                            product.media.map(m => (
                                                <a href={`/storage/products/${m}`} key={m}>
                                                    <img src={`/storage/products/${m}`} alt="Imagen del producto" data-uk-cover />
                                                </a>
                                            ))
                                        }
                                    </div>
                                    <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="" data-uk-slidenav-previous data-uk-slideshow-item="previous"></a>
                                    <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="" data-uk-slidenav-next data-uk-slideshow-item="next"></a>
                                </div>
                            ) : (
                                <div>
                                    <img src="/img/no-image.jpg" alt="Imagen del producto no disponible" className="uk-width-1-1" />
                                </div>
                            )
                        }
                    </div>

                    <div>
                        <div className="uk-card uk-card-default">
                            <div className="uk-card-header">
                                <h2 className="uk-card-title">
                                    {product?.name}
                                </h2>
                                <span>
                                    {product?.category}
                                </span>
                            </div>
                            <div className="uk-card-body">
                                <p>
                                    <strong>{formatCurrencyMXN(product?.price ?? 0)}</strong>
                                </p>
                                <p>
                                    <TextToHtml text={product?.summary ?? ''} />
                                </p>
                                <p>
                                    <strong>SKU: </strong>
                                    <span>
                                        {product?.sku}
                                    </span>
                                </p>
                                <p>
                                    <strong>ETIQUETAS: </strong>
                                    <span>
                                        {formatConjunction(product?.tags ?? [])}
                                    </span>
                                </p>
                                {!state?.isFromManagePage && <div className="uk-flex uk-flex-middle" data-uk-margin>
                                    <strong>COMPARTIR: </strong>
                                    <a href={`https://wa.me/?text=${window.location.href}`} target="_blank">
                                        <Icon path={mdiWhatsapp} size={1} />
                                    </a>
                                    <a onClick={handleCopyLink}>
                                        <Icon path={mdiLink} size={1} />
                                    </a>
                                </div>}
                            </div>
                        </div>

                        {!state?.isFromManagePage && <div className="uk-card uk-card-default uk-margin-small-top">
                            <div className="uk-card-body">
                                <ul data-uk-accordion="">
                                    <li>
                                        <a className="uk-accordion-title uk-text-default">
                                            Política de devolución
                                        </a>
                                        <div className="uk-accordion-content">
                                            <p>
                                                In convallis metus et felis scelerisque rhoncus. Sed eros velit, semper consectetur urna vitae, porta tincidunt felis.
                                                Aliquam erat volutpat. Nulla facilisis lacus non ante egestas suscipit. Praesent sed neque nisl.
                                                Maecenas rhoncus vehicula augue vel vulputate. Curabitur venenatis aliquam egestas. Donec dui tellus, placerat eu faucibus eget,
                                                facilisis quis odio. Proin elementum nec nulla pellentesque elementum. Vestibulum quis lectus hendrerit, dapibus massa malesuada,
                                                facilisis enim. Vestibulum sodales ipsum nec nulla varius cursus. Sed maximus lacinia rhoncus.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

            <section className="uk-section uk-section-small">
                <h3 id="Description">
                    Descripción
                </h3>
                <p>
                    <TextToHtml text={product?.generalInformation ?? ''} />
                </p>

                <div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                    <span className="uk-width-small@s">
                        Modelo (año):
                    </span>
                    <span>
                        <strong>
                            {product?.model}
                        </strong>
                    </span>
                </div>
                <div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                    <span className="uk-width-small@s">
                        Tipo:
                    </span>
                    <span>
                        <strong>
                            {product?.type}
                        </strong>
                    </span>
                </div>
                <div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                    <span className="uk-width-small@s">
                        Nivel:
                    </span>
                    <span>
                        <strong>
                            {product?.level}
                        </strong>
                    </span>
                </div>
                <div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                    <span className="uk-width-small@s">
                        Peso:
                    </span>
                    <span>
                        <strong>
                            {product?.weight}
                        </strong>
                    </span>
                </div>
            </section>

            <section className="uk-section uk-section-small">
                <h3 id="Specifications">
                    Especificaciones
                </h3>
                <p>
                    <TextToHtml text={product?.generalSpecifications ?? ''} />
                </p>
                {product?.specifications && product.specifications.map((spec, index) => (
                    <div key={spec.name}>
                        <h4 className={`${index > 0 ? 'uk-margin-small-top' : ''}`}>
                            {spec.name}
                        </h4>

                        {spec.specificationDetails.map(detail => (
                            <div key={detail.name} className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                                <span className="uk-width-small@s">
                                    {detail.name}:
                                </span>
                                <span>
                                    <strong>
                                        {detail.value}
                                    </strong>
                                </span>
                            </div>
                        ))}

                    </div>
                ))}
            </section>

            <section className="uk-section uk-section-small" data-uk-grid>
                <div>
                    <h3 id="SizeGuide">
                        Guía de tamaño
                    </h3>

                    <table className="uk-table">
                        <thead>
                            <tr>
                                <th className="uk-width-small">
                                    Radon Size
                                </th>
                                <th className="uk-width-small">
                                    CM
                                </th>
                                <th className="uk-width-small">
                                    EU
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {product?.sizeGuides.map(m => (
                                <tr key={m.size}>
                                    <td>
                                        <strong>{m.size}</strong>
                                    </td>
                                    <td>
                                        {m.cm}
                                    </td>
                                    <td>
                                        {m.eu}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
        </Content>
    );
}